<template>

    <!-- footer
    ================================================== -->
    <footer class="s-footer footer">


<div class="row footer__bottom">

    <div class="footer__about col-nine tab-full left">

        <div class="footer__contact col-five mob-full">
            <h3 class='footer-logo'>
                <img class="footer-logo__img" src="/images/Spawn_logo_512px_white.png"/>
                <span class="footer-logo__text">Spawnaride</span>
            </h3>
            <h4>{{ $t("Contact Us") }}</h4>
            <!-- <p>Phone: <a href="tel:18005555555">1-800-555-5555</a></p> -->
            <p>{{ $t("Need help or have questions?") }}<br> <br>
            <router-link class="btn btn--primary" :to="getLocaleLink('/contact-us')">{{ $t("Contact Us") }}</router-link>
            </p>
            <ul class="footer__social">
                <h4>{{ $t("Social Medias") }}</h4>
                <li><a target="_blank" href="https://www.facebook.com/profile.php?id=61567111434771"><i class="fab fa-facebook-f" aria-hidden="true"></i></a></li>
                <li><a target="_blank" class="custom-footer-social" href="https://x.com/Spawnaride"><img src="@/assets/socials/x-solid.svg" class="fab fa-custom" aria-hidden="true"/></a></li>
                <li>
                    <a target="_blank" class="custom-footer-social" href="https://www.tiktok.com/@Spawnaride/">
                        <img src="@/assets/socials/tiktok-brands-solid.svg" class="fab fa-custom" aria-hidden="true"/>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://www.instagram.com/Spawnaride/">
                        <i class="fab fa-instagram" aria-hidden="true"></i>
                    </a>
                </li>
            </ul>
        </div>
        <div class="footer__contact col-seven mob-full">

            <h4>{{ $t("About Spawnaride") }}</h4>

            <p>
                {{ $t("Spawnaride is the carpooling app that simplifies your daily commutes. Our mission is to make transportation more accessible, affordable, and eco-friendly, while offering an intuitive and secure platform. Join us and enjoy a hassle-free carpooling experience, wherever you are.") }}
            </p>


        </div>
    </div>

    <div class="col-three tab-full right end">
        <div class="row">
            <h4>Site links</h4>
            <ul class="footer__site-links">
                <li><a href="#home" class="smoothscroll">{{ $t("Introduction") }}</a></li>
                <li><a href="#about" class="smoothscroll">{{ $t("About") }}</a></li>
                <li><a href="#features" class="smoothscroll">{{ $t("Features") }}</a></li>
                <li><a href="#pricing" class="smoothscroll">{{ $t("Pricing") }}</a></li>
                <li><a href="#download" class="smoothscroll">{{ $t("Download") }}</a></li>
                <li></li>
            </ul>
        </div>
    </div>

    <div class="col-full ss-copyright">
        <span>&copy; Spawnaride Inc. </span>
        <span><router-link :to="getLocaleLink('/terms')">{{ $t("Terms") }}</router-link></span>
        <span><router-link :to="getLocaleLink('/privacy')">{{ $t("Privacy Policy") }}</router-link></span>
    </div>

</div> <!-- end footer__bottom -->

<div class="go-top">
    <a class="smoothscroll" title="Back to Top" href="#top"></a>
</div>

</footer> <!-- end s-footer -->
</template>
<script>
    export default {
        name: 'FooterView',
        props: {
        uri: String,
        getLocaleLink: Function
        },
    }
</script>
<style scoped>
    .footer-logo {
        font-size: 32px;
        color: #fff;
        margin: 0 0 30px;
        color: #8ace00;
        min-width: 250px;
    }
    .footer-logo__img {
        width: 44px;
    }
    .footer-logo__text {
        top: -10px;
        left: 10px;
        position: relative;
    }
    .fa-custom {
        display: block;
        top: 10;
        top: 10px;
        margin: 0;
        padding: 10px;
        width: 33px;
        margin: 0px 0 0;
    }
    .custom-footer-social {
        position: relative;
        transform: translate(0, 12px);
    }
    .footer__social {
        margin: 10px 0 0;
    }
    .btn--primary:hover {
        color: #000;
    }
</style>