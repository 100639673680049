<template>
     <!-- home
    ================================================== -->
    <section id="home" class="s-home target-section" data-parallax="scroll" data-image-src="images/s2.webp" data-natural-width=3000 data-natural-height=2000 data-position-y=center>

<div class="shadow-overlay"></div>

<div class="home-content">

    <div class="row home-content__main">

        <div class="home-content__left">
            <h1>
              {{ $t('Revolutionary Carpooling') }}
            </h1>
            <form @submit.prevent="submitSearch" class="search-form">
          <div class="form-group form-group__from">
            <div class="form-group__from__input-container">
              <input
                    type="text"
                    v-model="fromCitySearch"
                    @input="filterFromCities"
                    id="fromCity"
                    :placeholder="$t('From')"
                    class="input form-group__from__input"
                    required
                  />
                  <i v-if="selectedFromCity" @click="clearCityFrom()" class="fas fa-window-close form-group__from__input__close"></i>
            </div>

            <ul v-if="filteredFromCities.length" class="suggestions">
              <li v-for="city in filteredFromCities" :key="city.city_code" @click="selectFromCity(city)" class="suggestion-item">
                {{ city[$t('en')].city }} ({{ city[$t('en')].province }})
              </li>
            </ul>
          </div>
          <div class="form-group__switch" @click="switchFromTo()">
            <img src="@/assets/images/swap_brat.png"/>
          </div>
          <div class="form-group form-group__to">
            <div class="form-group__from__input-container">
              <input
                type="text"
                v-model="toCitySearch"
                @input="filterToCities"
                id="toCity"
                :placeholder="$t('To')"
                class="input form-group__from__input"
                required
              />
              <i v-if="selectedToCity" @click="clearCityTo()" class="fas fa-window-close form-group__from__input__close"></i>
            </div>
            <ul v-if="filteredToCities.length" class="suggestions">
              <li v-for="city in filteredToCities" :key="city.city_code" @click="selectToCity(city)" class="suggestion-item">
                {{ city[$t('en')].city }} ({{ city[$t('en')].province }})
              </li>
            </ul>
          </div>
          <div class="form-group form-group__when datepicker-wrapper">
            <flat-pickr
                placeholder="Date"
                hint="Date"
                v-model="dateFrom"
                id="dateFrom"
                ref="dateFrom"
                :config="{ disableMobile: true, dateFormat: 'Y-m-d' , allowInput: true}"
                class="input form-control"
                
            />
            <!-- Calendar Icon -->
            <span class="icon-wrapper" @click="openDatepicker">
              <i class="fas fa-calendar-alt"></i>
            </span>
          </div>    
          <button type="submit" class="btn btn--primary button form-group__button">{{ $t('Search') }}</button>
        </form>
            <!-- <h3>
              Incrivez-vous à Spawnaride et bénéficiez de 5 réservations gratuites ainsi qu'une inscription gratuite pendant un an ! Ne manquez pas cette offre exclusive pour simplifier vos trajets et économiser dès le lancement de l'applicatoin.
            </h3>

            <div class="home-content__btn-wrap" v-if="!preregistered">
              <router-link class="btn btn--primary home-content__btn" to="register">
                    Inscription
              </router-link>
            </div>
            <div class="home-content__btn-wrap" v-else>
              <h2 class="text-primary">Merci pour votre préinscription !</h2>
              <p class="text-white">Vous recevrez bientôt des informations et des mises à jour sur nos prochaines étapes.</p>
            </div> -->
        </div> <!-- end home-content__left-->

        <div class="home-content__right">
            <img src="images/hero-app-screens-800.png" srcset="images/hero-app-screens-800.png 1x, images/hero-app-screens-1600.png 2x">
        </div> <!-- end home-content__right -->

    </div> <!-- end home-content__main -->

    <ul class="home-content__social">
        <li><a target="_blank" href="https://www.facebook.com/profile.php?id=61567111434771">Facebook</a></li>
        <li><a target="_blank" href="https://x.com/spawnaride">X</a></li>
        <li><a target="_blank" href="https://www.tiktok.com/@spawnaride/">TikTok</a></li>
        <li><a target="_blank" href="https://www.instagram.com/spawnaride/">Instagram</a></li>
    </ul>

</div> <!-- end home-content -->

<a href="#about" class="home-scroll smoothscroll">
    <span class="home-scroll__text">{{ $t("Scroll") }}</span>
    <span class="home-scroll__icon"></span>
</a> 

</section> <!-- end s-home -->


<!-- about
================================================== -->
<section id="about" class="s-about target-section">

<div class="row section-header has-bottom-sep" data-aos="fade-up">
    <div class="col-full">
        <h1 class="display-1">
          {{ $t("The ideal solution for simple and affordable carpooling!") }}
        </h1>
        <p class="lead">
          {{ $t("Discover the app that transforms your travels. With an intuitive interface and innovative features, Spawnaride is designed to simplify your commutes and enhance your carpooling experience.") }}
        </p>
    </div>
</div>
<div class="row wide about-desc" data-aos="fade-up">
  <div class="col-full slick-slider about-desc__slider">
      <div class="about-desc__slide">
        <h3 class="item-title">{{ $t("Intuitive") }}</h3>
        <p>
          {{ $t("Navigate effortlessly with a user-friendly interface. Find and book a ride in just a few clicks, making carpooling accessible to everyone, regardless of their level of technological expertise.") }}
        </p>
      </div> 
      <div class="about-desc__slide">
        <h3 class="item-title">{{ $t("Intelligent") }}</h3>
        <p>
          {{ $t("Spawnaride uses advanced algorithms to quickly connect you with compatible drivers or passengers, providing you with the best ride options based on your preferences and schedule.") }}
        </p>
      </div> 
      <div class="about-desc__slide">
        <h3 class="item-title">{{ $t("Powerful") }}</h3>
        <p>
          {{ $t("With comprehensive features such as trip planning, user ratings, and real-time notifications, the app gives you complete control over your journeys.") }}
        </p>
      </div>
      <div class="about-desc__slide">
        <h3 class="item-title">{{ $t("Secure") }}</h3>
        <p>
          {{ $t("Your safety is our priority. Spawnaride includes user verification, data encryption, and assistance in case of issues during your ride, ensuring you a worry-free carpooling experience.") }}
        </p>
      </div>
  </div>
</div> 

</section>


<section id="about-how" class="s-about-how">

<div class="row"  style="display: none;">
   <div class="col-full video-bg" data-aos="fade-up">

        <div class="shadow-overlay"></div>

        <a class="btn-video" href="https://player.vimeo.com/video/14592941?color=00a650&title=0&byline=0&portrait=0" data-lity>
            <span class="video-icon"></span>
        </a>

        <div class="stats">
            <div class="item-stats">
                <span class="item-stats__num">
                    {{ $t("3.1M") }}
                </span>
                <span class="item-stats__title">
                    {{ $t("Downloads") }}
                </span>
            </div> <!-- end item-stats -->
            <div class="item-stats">
                <span class="item-stats__num">
                    {{ $t("24K") }}
                </span>
                <span class="item-stats__title">
                    {{ $t("Positive Reviews") }}
                </span>
            </div> <!-- end item-stats -->
        </div>
   </div> <!-- end video-bg -->
</div>

<div class="row process-wrap" style="margin-top: 0;padding-top: 12rem;;">

    <h2 class="display-2 text-center" data-aos="fade-up">
      {{ $t("How Does the App Work?") }}
    </h2>

    <div class="process" data-aos="fade-up">
        <div class="process__steps block-1-2 block-tab-full group">

            <div class="col-block step">
                <h3 class="item-title">
                  {{ $t("(Pre) Registration") }}
                </h3>
                <p>
                  {{ $t("Create your account in just a few minutes with a simple and quick sign-up process. Take advantage of the pre-registration offer for exclusive benefits like free reservations.") }}
                </p>
            </div>

            <div class="col-block step">
                <h3 class="item-title">{{ $t("Creating a Ride") }}</h3>
                <p>
                  {{ $t("Publish or search for a ride by specifying your starting point, destination, and schedule. The app automatically connects drivers and passengers to optimize shared rides.") }}
                </p>
            </div>
       
            <div class="col-block step">
                <h3 class="item-title">{{ $t("Booking") }}</h3>
                <p>
                  {{ $t("Book your seat in just a few clicks and receive an instant confirmation. Manage your reservations directly through the app.") }}                
                </p> 
            </div>

            <div class="col-block step">
                <h3 class="item-title">{{ $t("Travel") }}</h3>
                <p>
                  {{ $t("Head to your starting point and travel with peace of mind. Spawnaride ensures your journeys are safe, convenient, and cost-effective.") }}
                </p> 
            </div>  

        </div> <!-- end process__steps -->
   </div> <!-- end process -->
</div> <!-- end about-how -->

</section> <!-- end s-about-how -->

<section id="features" class="s-features target-section">

<div class="row section-header has-bottom-sep" data-aos="fade-up">
    <div class="col-full">
        <h1 class="display-1">
        {{ $t("Loaded with Features You'll Love") }}
        </h1>
        <p class="lead">
          {{ $t("Explore an innovative app designed to make your trips more convenient and enjoyable. Enjoy a variety of features that simplify every step of your travel experience.") }}
        </p>
    </div>
</div> <!-- end section-header -->

<div class="row features block-1-3 block-m-1-2">

    <div class="col-block item-feature" data-aos="fade-up">
        <div class="item-feature__icon">
            <i class="icon-car"></i>
        </div>
        <div class="item-feature__text">
            <h3 class="item-title">{{ $t("Trip Planning") }}</h3>
            <p>
              {{ $t("Explore an innovative app designed to make your trips more convenient and enjoyable. Enjoy a variety of features that simplify every step of your travel experience.") }}
            </p>
        </div>
    </div>

    <div class="col-block item-feature" data-aos="fade-up">
        <div class="item-feature__icon">
            <i class="icon-shield"></i>
        </div>
        <div class="item-feature__text">
            <h3 class="item-title">{{ $t("Safety First") }}</h3>
            <p>
              {{ $t("Spawnaride prioritizes safety with user and driver verification, as well as continuous monitoring to ensure secure rides.") }}
            </p>
        </div>
    </div>

    <div class="col-block item-feature" data-aos="fade-up">
        <div class="item-feature__icon">
            <i class="icon-chat"></i>
        </div>  
        <div class="item-feature__text">
            <h3 class="item-title">{{ $t("Live Chat") }}</h3>
            <p>
              {{ $t("Communicate with drivers and passengers through an integrated messaging system to easily coordinate trips and share real-time updates.") }}
            </p>
        </div>
    </div>

    <div class="col-block item-feature" data-aos="fade-up">
        <div class="item-feature__icon">
            <i class="icon-card"></i>
        </div>
        <div class="item-feature__text">
            <h3 class="item-title">{{ $t("Secure Payments") }}</h3>
            <p>
              {{ $t("Make payments directly through the app safely, using protected and user-friendly payment methods.") }}
            </p>
        </div>
    </div>

    <div class="col-block item-feature" data-aos="fade-up">
        <div class="item-feature__icon">
            <i class="icon-map"></i>
        </div>
        <div class="item-feature__text">
          <h3 class="item-title">{{ $t("Route Optimization") }}</h3>
            <p>
              {{  $t("The app helps you find the fastest and most cost-effective rides by automatically analyzing available options based on your preferences.") }}
            </p>
        </div>
    </div>

    <div class="col-block item-feature" data-aos="fade-up">
        <div class="item-feature__icon">
            <i class="icon-star"></i>
        </div>
        <div class="item-feature__text">
            <h3 class="item-title">{{ $t("Reviews and Ratings") }}</h3>
            <p>
              {{ $t("Share and read user reviews to make informed decisions about your rides. Ratings help maintain a trustworthy community.") }}
            </p>
        </div>
    </div>

</div>
</section>

<section id="pricing" class="s-pricing target-section">

<div class="row section-header align-center" data-aos="fade-up">
    <div class="col-full">
        <h1 class="display-1">
          {{ $t("Our Pricing") }}<br>{{ $t("Simple and Transparent.") }}
        </h1>
        <p class="lead">
          {{ $t("Discover our pricing options designed to suit every user. Enjoy our clear and surprise-free model for your carpooling journeys.") }}
        </p>
    </div>
</div>

<div class="row plans block-1-3 block-m-1-3 block-tab-full stack">

    <div class="col-block item-plan item-plan--popular" data-aos="fade-up">
        <div class="item-plan__block"> 
            <p>{{ $t("Upon mobile app launch") }}</p>
            <div class="item-plan__top-part">
                <h3 class="item-plan__title ">{{ $t("Pre-registration") }}</h3>
                <p class="item-plan__price">{{ $t("Free") }}</p>
                <p class="item-plan__per" style="color:red">{{ $t("Forever") }}</p>
            </div>

            <div class="item-plan__bottom-part">
                <ul class="item-plan__features">
                    <li>{{ $t("5 Bonus Reservations") }}</li>
                    <li>{{ $t("Premium Access")}}</li>
                    <li>{{ $t("Customer Support") }}</li>
                    <li>{{ $t("Advantageous Pricing") }}</li>
                </ul>
                <router-link class="btn btn--primary home-content__btn" :to="getLocaleLink('register')">
                    {{ $t("Pre-registration") }}
              </router-link>
            </div>  
        
        </div>
    </div>

    <div class="col-block item-plan" data-aos="fade-up">
        <div class="item-plan__block"> 
          <p>{{ $t("Upon mobile app launch") }}</p>
            <div class="item-plan__top-part">
                <h3 class="item-plan__title black-text">{{ $t("Registration") }}</h3>
                <p class="item-plan__price black-text">{{ $t("$9.95") }}</p>
                <p class="item-plan__per black-text">{{ $t("Per month") }}</p>
            </div>

            <div class="item-plan__bottom-part  black-text">
                <ul class="item-plan__features  black-text">
                    <li>{{ $t("Pre-registration Benefits") }}</li>
                    <li>{{ $t("Premium Access") }}</li>
                    <li>{{ $t("Customer Support") }}</li>
                    <li>{{ $t("Advantageous Pricing") }}</li>
                </ul>
                <router-link class="btn btn--primary home-content__btn" :to="getLocaleLink('register')">
                    {{ $t("Registration") }}
                </router-link>
            </div>
        
        </div>
    </div> <!-- end item-plan -->
    <div class="col-block item-plan" data-aos="fade-up">
        <div class="item-plan__block"> 
          <p>{{ $t("Available Forever") }}</p>
            <div class="item-plan__top-part">
                <h3 class="item-plan__title black-text">{{ $t("Basic") }}</h3>
                <p class="item-plan__price black-text">{{ $t("Free") }}</p>
                <p class="item-plan__per black-text">{{ $t("Forever") }}</p>
            </div>

            <div class="item-plan__bottom-part  black-text">
                <ul class="item-plan__features  black-text">
                    <li>{{ $t("Accessible") }}</li>
                    <li>{{ $t("Basic Access") }}</li>
                    <li>{{ $t("Email Support") }}</li>
                    <li>{{ $t("Regular Pricing") }}</li>
                </ul>
                <router-link class="btn btn--primary home-content__btn" :to="getLocaleLink('register')">
                    {{ $t("Registration") }}
                </router-link>
            </div>
        </div>
    </div>
</div>

</section>

<section id="download" class="s-download target-section">

<div class="row section-header align-center" data-aos="fade-up">
    <div class="col-full">
        <h1 class="display-1 black-text">
            {{ $t("Join Our Community") }}
        </h1>
        <p class="lead black-text">
          {{ $t("Become part of a dynamic carpooling community. Share your experiences, enjoy exclusive benefits, and connect with a network of reliable drivers and passengers.") }}
        </p>
    </div>
</div>

<div class="row">
    <div class="col-full text-center">
        <ul class="download-badges" data-aos="fade-up">
        <p class="lead black-text">
            {{ $t("Use the web app in the meantime.") }}
        </p>
        <router-link class="btn" :to="getLocaleLink('/register')">{{ $t("Web App") }}</router-link>
        </ul>
        <ul class="download-badges" data-aos="fade-up">
            <p class="lead black-text">
            {{ $t("Coming soon on App Store and Google Play Store.") }}
        </p>
        <li><a href="#home" title="" class="smoothscroll badge-appstore">{{ $t("App Store") }}</a></li>
        <li><a href="#home" title="" class="smoothscroll badge-googleplay">{{ $t("Play Store") }}</a></li>
        </ul>
    </div> 
</div>

<div class="row download-bottom-image" data-aos="fade-up">
    <img src="images/app-screen-1400.png" style="display:none"
        srcset="images/app-screen-600.png 600w, images/app-screen-1400.png 1400w, images/app-screen-2800.png 2800w"
        sizes="(max-width: 2800px) 100vw, 2800px"
        alt="App Screenshots">
</div>

</section> <!-- end s-download -->

</template>

<script>
import FlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
export default {
  name: 'LandingPage',
  mounted () {
  },
  components: {
    FlatPickr
  },
  props: {
    uri: String,
    getLocaleLink: Function
  },
  data() {
    return {
        currentLocale: this.$i18n.locale,
        cities: [
            // Quebec Cities
            {
                city_code: 'MTL',
                province_code: 'QC',
                fr: { city: 'Montréal', province: 'Québec' },
                en: { city: 'Montreal', province: 'Quebec' }
            },
            {
                city_code: 'LAV',
                province_code: 'QC',
                fr: { city: 'Laval', province: 'Québec' },
                en: { city: 'Laval', province: 'Quebec' }
            },
            {
                city_code: 'QUE',
                province_code: 'QC',
                fr: { city: 'Québec', province: 'Québec' },
                en: { city: 'Quebec City', province: 'Quebec' }
            },
            {
                city_code: 'GAT',
                province_code: 'QC',
                fr: { city: 'Gatineau', province: 'Québec' },
                en: { city: 'Gatineau', province: 'Quebec' }
            },
            {
                city_code: 'LON',
                province_code: 'QC',
                fr: { city: 'Longueuil', province: 'Québec' },
                en: { city: 'Longueuil', province: 'Quebec' }
            },
            {
                city_code: 'SHE',
                province_code: 'QC',
                fr: { city: 'Sherbrooke', province: 'Québec' },
                en: { city: 'Sherbrooke', province: 'Quebec' }
            },
            {
                city_code: 'TR',
                province_code: 'QC',
                fr: { city: 'Trois-Rivières', province: 'Québec' },
                en: { city: 'Trois-Rivières', province: 'Quebec' }
            },
            {
                city_code: 'SAG',
                province_code: 'QC',
                fr: { city: 'Saguenay', province: 'Québec' },
                en: { city: 'Saguenay', province: 'Quebec' }
            },

            // Ontario Cities
            {
                city_code: 'TOR',
                province_code: 'ON',
                fr: { city: 'Toronto', province: 'Ontario' },
                en: { city: 'Toronto', province: 'Ontario' }
            },
            {
                city_code: 'KNG',
                province_code: 'ON',
                fr: { city: 'Kingston', province: 'Ontario' },
                en: { city: 'Kingston', province: 'Ontario' }
            },
            {
                city_code: 'OTT',
                province_code: 'ON',
                fr: { city: 'Ottawa', province: 'Ontario' },
                en: { city: 'Ottawa', province: 'Ontario' }
            },
            {
                city_code: 'MIS',
                province_code: 'ON',
                fr: { city: 'Mississauga', province: 'Ontario' },
                en: { city: 'Mississauga', province: 'Ontario' }
            },
            {
                city_code: 'BRA',
                province_code: 'ON',
                fr: { city: 'Brampton', province: 'Ontario' },
                en: { city: 'Brampton', province: 'Ontario' }
            },
            {
                city_code: 'LON',
                province_code: 'ON',
                fr: { city: 'London', province: 'Ontario' },
                en: { city: 'London', province: 'Ontario' }
            },
            {
                city_code: 'KIC',
                province_code: 'ON',
                fr: { city: 'Kitchener', province: 'Ontario' },
                en: { city: 'Kitchener', province: 'Ontario' }
            },
            {
                city_code: 'WIN',
                province_code: 'ON',
                fr: { city: 'Windsor', province: 'Ontario' },
                en: { city: 'Windsor', province: 'Ontario' }
            },
            {
                city_code: 'NIAG',
                province_code: 'ON',
                fr: { city: 'Niagara Falls', province: 'Ontario' },
                en: { city: 'Niagara Falls', province: 'Ontario' }
            },
            {
            "city_code": "VAN",
            "province_code": "BC",
            "fr": { "city": "Vancouver", "province": "Colombie-Britannique" },
            "en": { "city": "Vancouver", "province": "British Columbia" }
            },
            {
            "city_code": "NOU",
            "province_code": "BC",
            "fr": { "city": "North Vancouver", "province": "Colombie-Britannique" },
            "en": { "city": "North Vancouver", "province": "British Columbia" }
            },
            {
            "city_code": "SOU",
            "province_code": "BC",
            "fr": { "city": "South Vancouver", "province": "Colombie-Britannique" },
            "en": { "city": "South Vancouver", "province": "British Columbia" }
            },
            {
            "city_code": "WES",
            "province_code": "BC",
            "fr": { "city": "West Vancouver", "province": "Colombie-Britannique" },
            "en": { "city": "West Vancouver", "province": "British Columbia" }
            },
            {
            "city_code": "RIC",
            "province_code": "BC",
            "fr": { "city": "Richmond", "province": "Colombie-Britannique" },
            "en": { "city": "Richmond", "province": "British Columbia" }
            },
            {
            "city_code": "BUR",
            "province_code": "BC",
            "fr": { "city": "Burnaby", "province": "Colombie-Britannique" },
            "en": { "city": "Burnaby", "province": "British Columbia" }
            },
            {
            "city_code": "DEL",
            "province_code": "BC",
            "fr": { "city": "Delta", "province": "Colombie-Britannique" },
            "en": { "city": "Delta", "province": "British Columbia" }
            },
            {
            "city_code": "SUR",
            "province_code": "BC",
            "fr": { "city": "Surrey", "province": "Colombie-Britannique" },
            "en": { "city": "Surrey", "province": "British Columbia" }
            },
            {
            "city_code": "LAD",
            "province_code": "BC",
            "fr": { "city": "Ladner", "province": "Colombie-Britannique" },
            "en": { "city": "Ladner", "province": "British Columbia" }
            },
            {
            "city_code": "POR",
            "province_code": "BC",
            "fr": { "city": "Port Coquitlam", "province": "Colombie-Britannique" },
            "en": { "city": "Port Coquitlam", "province": "British Columbia" }
            },
            {
            "city_code": "COQ",
            "province_code": "BC",
            "fr": { "city": "Coquitlam", "province": "Colombie-Britannique" },
            "en": { "city": "Coquitlam", "province": "British Columbia" }
            },
            {
            "city_code": "MAP",
            "province_code": "BC",
            "fr": { "city": "Maple Ridge", "province": "Colombie-Britannique" },
            "en": { "city": "Maple Ridge", "province": "British Columbia" }
            },
            {
            "city_code": "LANG",
            "province_code": "BC",
            "fr": { "city": "Langley", "province": "Colombie-Britannique" },
            "en": { "city": "Langley", "province": "British Columbia" }
            },
            {
            "city_code": "PITT",
            "province_code": "BC",
            "fr": { "city": "Pitt Meadows", "province": "Colombie-Britannique" },
            "en": { "city": "Pitt Meadows", "province": "British Columbia" }
            },
            {
            "city_code": "ABO",
            "province_code": "BC",
            "fr": { "city": "Abbotsford", "province": "Colombie-Britannique" },
            "en": { "city": "Abbotsford", "province": "British Columbia" }
            },
            {
            "city_code": "CAL",
            "province_code": "AB",
            "fr": { "city": "Calgary", "province": "Alberta" },
            "en": { "city": "Calgary", "province": "Alberta" }
            },
            {
            "city_code": "EDM",
            "province_code": "AB",
            "fr": { "city": "Edmonton", "province": "Alberta" },
            "en": { "city": "Edmonton", "province": "Alberta" }
            },
            {
            "city_code": "RED",
            "province_code": "AB",
            "fr": { "city": "Red Deer", "province": "Alberta" },
            "en": { "city": "Red Deer", "province": "Alberta" }
            },
            {
            "city_code": "LAC",
            "province_code": "AB",
            "fr": { "city": "Lacombe", "province": "Alberta" },
            "en": { "city": "Lacombe", "province": "Alberta" }
            },
            {
            "city_code": "LETH",
            "province_code": "AB",
            "fr": { "city": "Lethbridge", "province": "Alberta" },
            "en": { "city": "Lethbridge", "province": "Alberta" }
            },
            {
            "city_code": "AIR",
            "province_code": "AB",
            "fr": { "city": "Airdrie", "province": "Alberta" },
            "en": { "city": "Airdrie", "province": "Alberta" }
            },
            {
            "city_code": "MED",
            "province_code": "AB",
            "fr": { "city": "Medicine Hat", "province": "Alberta" },
            "en": { "city": "Medicine Hat", "province": "Alberta" }
            },
            {
            "city_code": "COLE",
            "province_code": "AB",
            "fr": { "city": "Cold Lake", "province": "Alberta" },
            "en": { "city": "Cold Lake", "province": "Alberta" }
            },
            {
            "city_code": "GRAN",
            "province_code": "AB",
            "fr": { "city": "Grand Prairie", "province": "Alberta" },
            "en": { "city": "Grande Prairie", "province": "Alberta" }
            },
            {
            "city_code": "BEA",
            "province_code": "AB",
            "fr": { "city": "Fort McMurray", "province": "Alberta" },
            "en": { "city": "Fort McMurray", "province": "Alberta" }
            },
            {
            "city_code": "STAL",
            "province_code": "AB",
            "fr": { "city": "St. Albert", "province": "Alberta" },
            "en": { "city": "St. Albert", "province": "Alberta" }
            },
            {
            "city_code": "OKOT",
            "province_code": "AB",
            "fr": { "city": "Okotoks", "province": "Alberta" },
            "en": { "city": "Okotoks", "province": "Alberta" }
            },
            {
            "city_code": "COCHR",
            "province_code": "AB",
            "fr": { "city": "Cochrane", "province": "Alberta" },
            "en": { "city": "Cochrane", "province": "Alberta" }
            },
            {
            "city_code": "DRUM",
            "province_code": "AB",
            "fr": { "city": "Drumheller", "province": "Alberta" },
            "en": { "city": "Drumheller", "province": "Alberta" }
            },
            {
            "city_code": "BRO",
            "province_code": "AB",
            "fr": { "city": "Brooks", "province": "Alberta" },
            "en": { "city": "Brooks", "province": "Alberta" }
            },
            {
            "city_code": "REG",
            "province_code": "SK",
            "fr": { "city": "Regina", "province": "Saskatchewan" },
            "en": { "city": "Regina", "province": "Saskatchewan" }
            },
            {
            "city_code": "SASK",
            "province_code": "SK",
            "fr": { "city": "Saskatoon", "province": "Saskatchewan" },
            "en": { "city": "Saskatoon", "province": "Saskatchewan" }
            },
            {
            "city_code": "PRIN",
            "province_code": "SK",
            "fr": { "city": "Prince Albert", "province": "Saskatchewan" },
            "en": { "city": "Prince Albert", "province": "Saskatchewan" }
            },
            {
            "city_code": "MOOSE",
            "province_code": "SK",
            "fr": { "city": "Moose Jaw", "province": "Saskatchewan" },
            "en": { "city": "Moose Jaw", "province": "Saskatchewan" }
            },
            {
            "city_code": "SWIFT",
            "province_code": "SK",
            "fr": { "city": "Swift Current", "province": "Saskatchewan" },
            "en": { "city": "Swift Current", "province": "Saskatchewan" }
            },
            {
            "city_code": "YORK",
            "province_code": "SK",
            "fr": { "city": "Yorkton", "province": "Saskatchewan" },
            "en": { "city": "Yorkton", "province": "Saskatchewan" }
            },
            {
            "city_code": "ESTEV",
            "province_code": "SK",
            "fr": { "city": "Estevan", "province": "Saskatchewan" },
            "en": { "city": "Estevan", "province": "Saskatchewan" }
            },
            {
            "city_code": "NIP",
            "province_code": "SK",
            "fr": { "city": "Nipawin", "province": "Saskatchewan" },
            "en": { "city": "Nipawin", "province": "Saskatchewan" }
            },
            {
            "city_code": "HUMB",
            "province_code": "SK",
            "fr": { "city": "Humboldt", "province": "Saskatchewan" },
            "en": { "city": "Humboldt", "province": "Saskatchewan" }
            },
            {
            "city_code": "WAT",
            "province_code": "SK",
            "fr": { "city": "Watrous", "province": "Saskatchewan" },
            "en": { "city": "Watrous", "province": "Saskatchewan" }
            },
            {
            "city_code": "SHELL",
            "province_code": "SK",
            "fr": { "city": "Shellbrook", "province": "Saskatchewan" },
            "en": { "city": "Shellbrook", "province": "Saskatchewan" }
            },
            {
            "city_code": "LLOY",
            "province_code": "SK",
            "fr": { "city": "Lloydminster", "province": "Saskatchewan" },
            "en": { "city": "Lloydminster", "province": "Saskatchewan" }
            },
            {
            "city_code": "BATTLE",
            "province_code": "SK",
            "fr": { "city": "Battleford", "province": "Saskatchewan" },
            "en": { "city": "Battleford", "province": "Saskatchewan" }
            },
            {
            "city_code": "MART",
            "province_code": "SK",
            "fr": { "city": "Martensville", "province": "Saskatchewan" },
            "en": { "city": "Martensville", "province": "Saskatchewan" }
            },
            {
            "city_code": "WPG",
            "province_code": "MB",
            "fr": { "city": "Winnipeg", "province": "Manitoba" },
            "en": { "city": "Winnipeg", "province": "Manitoba" }
            },
            {
            "city_code": "BRAND",
            "province_code": "MB",
            "fr": { "city": "Brandon", "province": "Manitoba" },
            "en": { "city": "Brandon", "province": "Manitoba" }
            },
            {
            "city_code": "STE",
            "province_code": "MB",
            "fr": { "city": "Steinbach", "province": "Manitoba" },
            "en": { "city": "Steinbach", "province": "Manitoba" }
            },
            {
            "city_code": "THOMP",
            "province_code": "MB",
            "fr": { "city": "Thompson", "province": "Manitoba" },
            "en": { "city": "Thompson", "province": "Manitoba" }
            },
            {
            "city_code": "DAUPH",
            "province_code": "MB",
            "fr": { "city": "Dauphin", "province": "Manitoba" },
            "en": { "city": "Dauphin", "province": "Manitoba" }
            },
            {
            "city_code": "FLIN",
            "province_code": "MB",
            "fr": { "city": "Flin Flon", "province": "Manitoba" },
            "en": { "city": "Flin Flon", "province": "Manitoba" }
            },
            {
            "city_code": "MORD",
            "province_code": "MB",
            "fr": { "city": "Morden", "province": "Manitoba" },
            "en": { "city": "Morden", "province": "Manitoba" }
            },
            {
            "city_code": "PORT",
            "province_code": "MB",
            "fr": { "city": "Portage la Prairie", "province": "Manitoba" },
            "en": { "city": "Portage la Prairie", "province": "Manitoba" }
            },
            {
            "city_code": "SELK",
            "province_code": "MB",
            "fr": { "city": "Selkirk", "province": "Manitoba" },
            "en": { "city": "Selkirk", "province": "Manitoba" }
            },
            {
            "city_code": "GIMLI",
            "province_code": "MB",
            "fr": { "city": "Gimli", "province": "Manitoba" },
            "en": { "city": "Gimli", "province": "Manitoba" }
            },
            {
            "city_code": "NEEP",
            "province_code": "MB",
            "fr": { "city": "Neepawa", "province": "Manitoba" },
            "en": { "city": "Neepawa", "province": "Manitoba" }
            },
            {
            "city_code": "WINNI",
            "province_code": "MB",
            "fr": { "city": "Winnipeg Beach", "province": "Manitoba" },
            "en": { "city": "Winnipeg Beach", "province": "Manitoba" }
            },
            {
            "city_code": "BIRT",
            "province_code": "MB",
            "fr": { "city": "Birtle", "province": "Manitoba" },
            "en": { "city": "Birtle", "province": "Manitoba" }
            },
            {
            "city_code": "CARB",
            "province_code": "MB",
            "fr": { "city": "Carberry", "province": "Manitoba" },
            "en": { "city": "Carberry", "province": "Manitoba" }
            }
  
        ],
        fromCitySearch: '',
        toCitySearch: '',
        filteredFromCities: [],
        filteredToCities: [],
        selectedFromCity: '',
        selectedToCity: '',
        dateFrom: this.getCurrentDate(), // Set default to today
        dateTo: '', // No default for To Date
        searchResults: [],
      email: '',
      preregistered: false,
      features: [
        { title: 'Partage Facile', description: 'Partagez vos trajets avec des amis et d\'autres utilisateurs.' },
        { title: 'Sécurisé', description: 'Des paiements sécurisés pour chaque réservation.' },
        { title: 'Multi-plateforme', description: 'Disponible sur iOS, Android et Web.' },
      ],
    };
  },
  methods: {
    changeLocale() {
      this.$i18n.locale = this.currentLocale
    },
    openDatepicker() {
        const dateCalendar = document.getElementById('dateFrom');
        dateCalendar.click();
    },
    switchFromTo () {
        const fromCitySearch = this.fromCitySearch;

        const toCitySearch = this.toCitySearch;

        const selectedFromCity = this.selectedFromCity;
        const selectedToCity = this.selectedToCity;
        
        this.fromCitySearch=toCitySearch;
        this.toCitySearch=fromCitySearch;
        this.selectedFromCity=selectedToCity;
        this.selectedToCity=selectedFromCity;
    },
    clearCityFrom() {
        this.fromCitySearch = undefined; // Set the input field to the selected city
        this.selectedFromCity = undefined; // Store the selected city code
        this.filteredFromCities = []; // Clear suggestions
    },
    clearCityTo() {
        this.toCitySearch = undefined; // Set the input field to the selected city
        this.selectedToCity = undefined; // Store the selected city code
        this.filteredFromCities = []; // Clear suggestions
    },
    formatDepartureTime(dateTime) {
        const departureDate = new Date(dateTime);
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        
        if (departureDate.toDateString() === tomorrow.toDateString()) {
          return "Tomorrow at " + departureDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else {
          return departureDate.toLocaleString(); // or any other format you prefer
        }
      },
      loadCities() {
        this.filteredFromCities = this.cities; // Initialize with all cities
        this.filteredToCities = this.cities; // Initialize with all cities
      },
    filterFromCities() {
        if (this.fromCitySearch) {
        this.filteredFromCities = this.cities.filter(city =>
            city.en.city.toLowerCase().includes(this.fromCitySearch.toLowerCase()) ||
            city.fr.city.toLowerCase().includes(this.fromCitySearch.toLowerCase())
        );
        } else {
        this.filteredFromCities = this.cities; // Reset to all cities if input is empty
        }
    },
    filterToCities() {
        if (this.toCitySearch) {
        this.filteredToCities = this.cities.filter(city =>
            city.en.city.toLowerCase().includes(this.toCitySearch.toLowerCase()) ||
            city.fr.city.toLowerCase().includes(this.toCitySearch.toLowerCase())
        );
        } else {
        this.filteredToCities = this.cities; // Reset to all cities if input is empty
        }
    },
    selectFromCity(city) {
        this.fromCitySearch = city.en.city; // Set the input field to the selected city
        this.selectedFromCity = city.en.city; // Store the selected city code
        this.filteredFromCities = []; // Clear suggestions
    },
    selectToCity(city) {
        this.toCitySearch = city.en.city; // Set the input field to the selected city
        this.selectedToCity = city.en.city; // Store the selected city code 
        this.filteredToCities = []; // Clear suggestions
    },
    getCurrentDate() {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = today.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
      },
    async preregister () {
      await this.sendPreRegistrationEmail(this.email);
      this.preregistered = true;
    },
    async submitSearch() {
        if (!this.selectedFromCity || !this.selectedToCity) {
          alert("Please select both from and to cities.");
          return;
        }
        try {
                this.$router.push({ 
                    name: "CarSharingList", 
                    query: 
                        { 
                            selectedToCity: this.selectedToCity, 
                            selectedFromCity: this.selectedFromCity,
                            dateFrom: this.dateFrom
                        } 
                    });
        } catch (error) {
          console.error("Error fetching search results:", error);
          alert("Failed to fetch search results. Please try again later.");
        } 
        // finally {}
      },
    async sendPreRegistrationEmail(email) {
      const url = this.uri + '/pre-register'; // Remplacez par l'URL de votre API

      const data = {
          email: email
      };

      try {
          const response = await fetch(url, {
              method: 'POST', // Ou 'GET', selon votre API
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          });

          if (!response.ok) {
              throw new Error('Erreur de réseau : ' + response.statusText);
          }

          const responseData = await response.json();
          console.log('Préinscription réussie :', responseData);
      } catch (error) {
          console.error('Erreur lors de l\'envoi de la préinscription :', error);
      }
  },
    submitPreinscription() {
      alert('Merci de vous être préinscrit !');
    },
  },
};
</script>
<style scoped>
    .bf-filter {
        background-color: #ffffff;
        padding: 1px 10px 10px;
        margin: 10px 0 0;
        border-radius: 10px;
    }
    .bf-filter input {
        width: 100%;
        border: 1px solid #b9b9b9;
    }
    .bf-filter a {
        width: 100%;
    }
    .item-plan--popular .item-plan__block {
      background-color: #8Ace00;
    }
   .item-plan__block {
        background: #f0f0f0;
   }
   .item-plan__block a {
    width: 100%;
   }
   .search-form {
    width: 100%;
    display: inline-block;
    background: rgb(228, 228, 228);
    border-radius: 10px;
    margin: 0;
    padding: 0px;
    height: auto;
    min-height: 0;
  }
  
  .form-group {
  }
  .form-group__from {
    width: calc((100% - 400px) / 2);
    float: left;
    display: inline-block;
    margin: 0 10px 0 10px;

  }
  .form-group__from__input-container {
    width: 100%;
    position: relative;
    padding: 0;
    margin: 10px 0 10px 0;
  }
  .form-group__from__input {
    position: relative;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
  }
  .form-group__from__input__close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    padding: 20px 15px;
    font-size: 20px;
  }
  .form-group__switch {
    width: 60px;
    float: left;
    display: inline-block;
    margin: 15px 10px 0 20px;
    border-radius: 10px;
    padding: 10px 20px 0px 10px;
    margin: 15px 0;
  }
  .form-group__to {
    width: calc((100% - 400px) / 2);
    float: left;
    display: inline-block;
    margin: 0 10px 0 0;
  }
  .form-group__when {
    width: 140px;
    float: left;
    display: inline-block;
    margin: 0 20px 0 0;
    height: auto;
    position: relative;
  }
  .form-group__when input {
    padding: 20px 10px;
    font-size: 16px;
    width: 140px;
    margin: 10px 0;
  }
  .form-group__button {
    width: 140px;
    float: right;
    margin: 13px 10px 0 0;
    height: auto;
    padding: 1px 0!important;
    display: inline-block;
    border-radius: 10px!important;
  }
  .input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
  }
  ::placeholder {
      color: #000000;
      opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder { /* Edge 12 -18 */
      color: #000000;
    }
  
  
  .suggestions {
    list-style-type: none;
    padding: 0;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    position: absolute;
    z-index:100;

  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
    .search-form {
      position: relative;
      padding: 10px 0;
    }
    .form-group {
      width: calc(100% - 20px)!important;
      margin: 0 10px;
    }
    .form-group__from__input-container {
      margin: 0;
    }
    .form-group__switch {
      position: absolute;
      right: 90px;
      background: rgb(204 204 204);
      padding: 2px;
      top: 36px;
      z-index: 10;
      padding: 4px;
      transform: rotate(90deg);
      width: 42px;
      height: 42px;
    }
    .form-group__switch img {
      margin: 0;
      padding: 0;
      background-color: #444444;
      border-radius: 7px;
      padding: 5px;
      width: 100px;
    }
    .form-group__when {
      width: calc(100% - 20px)!important;
      margin: 0 10px;
    }
    #dateFrom {
      margin: 0;
      width: 100%;
    }
    .button.form-group__button {
      width: calc(100% - 20px);
    }
    .driver-info {
      width: 100%;
    }
    .ride-details {
      width: 100%;
    }
    .car-info {
      width: 100%;
    }
    .result-card {
      display: block;
    }
    .form-group__when {
      
    }
    /* .icon-wrapper {
      display: none;
    } */
     
.icon-wrapper {
  cursor: pointer;
  color: #8ace00;
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0,-50%);
} 
.flatpickr-day.startRange,  
.flatpickr-day.endRange {
  background: #FF5722 !important; /* Orange for start/end of range */
  color: #fff !important;
}
.flatpickr-day.today {
  background: #8ace00 !important; /* Blue background for today */
  color: #fff !important;
}
.flatpickr-day.today.selected {
    background: #8ace00;
}
.icon-wrapper:hover {
  color: #75af00;
  font-size: 30px;
}

.fas.fa-calendar-alt {
  font-size: 16px;
}
  
</style>