<template>
    <div class="app-page">
        <div class="refer-friend">
            <h1>Référez un ami et gagnez un crédit pour une réservation gratuite !</h1>
        
            <div v-if="submitted">
                <p>Merci pour votre recommandation !</p>
                <p>Un crédit de réservation gratuite sera ajouté à votre compte une fois que votre ami aura complété sa première réservation.</p>
            </div>
        
            <div v-else>
                <form @submit.prevent="submitReferral">
                <!-- Section pour les informations de l'ami référé -->
                <div class="form-group">
                    <label for="friendName">Nom de votre ami :</label>
                    <input
                    id="friendName"
                    v-model="friendName"
                    type="text"
                    placeholder="Entrez le nom de votre ami"
                    required
                    />
                </div>
        
                <div class="form-group">
                    <label for="friendEmail">Email de votre ami :</label>
                    <input
                    id="friendEmail"
                    v-model="friendEmail"
                    type="email"
                    placeholder="Entrez l'email de votre ami"
                    required
                    />
                </div>
        
                <!-- Message personnalisé optionnel -->
                <div class="form-group">
                    <label for="message">Message personnalisé (facultatif) :</label>
                    <textarea
                    id="message"
                    v-model="message"
                    placeholder="Ajoutez un message pour votre ami (facultatif)"
                    ></textarea>
                </div>
        
                <button type="submit" class="submit-button">Envoyer la recommandation</button>
                </form>
            </div>
            </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      uri: String
    },
    data() {
      return {
        yourName: "",
        yourEmail: "",
        friendName: "",
        friendEmail: "",
        message: "",
        submitted: false,
      };
    },
    methods: {
      submitReferral() {
        // Vérification des données
        if (!this.yourName || !this.yourEmail || !this.friendName || !this.friendEmail) {
          alert("Veuillez remplir tous les champs obligatoires.");
          return;
        }
  
        // Simulez l'envoi des données à un serveur
        const referralData = {
          yourName: this.yourName,
          yourEmail: this.yourEmail,
          friendName: this.friendName,
          friendEmail: this.friendEmail,
          message: this.message,
        };
  
        console.log("Données de la recommandation :", referralData);
  
        // Afficher un message de succès
        this.submitted = true;
      },
    },
  };
  </script>
  
  <style scoped>
  .refer-friend {
    max-width: 600px;
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  textarea {
    resize: none;
    min-height: 100px;
  }
  
  .submit-button {
    display: block;
    width: 100%;
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  p {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }
  </style>
  