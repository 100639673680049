import { createRouter, createWebHistory, RouterView} from 'vue-router';

import LandingPage from './components/LandingPage.vue';
import LoginPage from './components/LoginPage.vue';
import ProfilePage from './components/ProfilePage.vue';
import ChatList from './components/ChatList.vue';
import CarSharingList from './components/CarSharingList.vue';
import PostRide from './components/PostRide.vue';
import RegisterPage from './components/RegisterPage.vue';
import ChatDetail from "@/components/ChatDetail.vue";
import RidesPage from "@/components/RidesPage.vue";
import Dashboard from "@/components/DashboardLayout.vue";
import RideDetailsPage from "@/components/RideDetailsPage.vue";
import BookedRides from './components/BookedRides.vue';
import RequestBookRide from './components/RequestBookRide.vue';
import Account from './components/AccountPage.vue';
import EditProfile from './components/EditDriverInfos.vue';
import NotificationsPage from './components/NotificationsPage.vue';
import AboutPage from './components/AboutPage.vue';
import ImpactPage from './components/ImpactPage.vue';
import PrivacyPage from './components/PrivacyPage.vue';
import DeleteAccount from './components/DeleteAccount.vue';
import TermsPage from './components/TermsPage.vue';
import StatsPage from './components/StatsPage.vue';
import CarsPage from './components/CarsPage.vue';
import BookRide from './components/BookRide.vue';
import ContactComponent from './components/ContactComponent.vue';
import DriverEvaluation from './components/DriverEvaluation.vue';
import ReferFriend from './components/ReferFriend.vue';

// import AboutComponent from './components/AboutComponent.vue';
// import Services from './components/Services.vue';
// import Contact from './components/ContactComponent.vue';

const routes = [{
  path: '/:locale?',
  component: RouterView,
  children: [
  {
    path: '',
    name: 'LandingPage',
    component: LandingPage,
  },  
  {
    path: 'driver-evaluation',
    name: 'DriverEvaluation',
    component: DriverEvaluation,
  },
  {
    path: 'contact-us',
    name: 'ContactComponent',
    component: ContactComponent,
  },
  {
    path: 'delete-account',
    name: 'DeleteAccount',
    component: DeleteAccount,
  },
  {
    path: 'terms',
    name: 'TermsPage',
    component: TermsPage,
  },
  {
    path: 'stats',
    name: 'StatsPage',
    component: StatsPage,
  },
  {
    path: 'driver-infos',
    name: 'EditDriverInfos',
    component: EditProfile,
  },
  {
    path: 'refer-friend',
    name: 'ReferFriend',
    component: ReferFriend,
  },
  {
    path: 'notifications',
    name: 'NotificationsPage',
    component: NotificationsPage,
  },
  {
    path: 'about',
    name: 'AboutPage',
    component: AboutPage,
  },
  {
    path: 'impact',
    name: 'ImpactPage',
    component: ImpactPage,
  },
  {
    path: 'privacy',
    name: 'PrivacyPage',
    component: PrivacyPage,
  },
  {
    path: 'login',
    name: 'LoginPage',
    component: LoginPage,
  },
  {
    path: 'rideDetailsPage',
    name: 'RideDetailsPage',
    component: RideDetailsPage,
  },
  {
    path: 'dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: 'account',
    name: 'Account',
    component: Account,
  },
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   component: Dashboard,
  // },
  {
    path: 'my-cars',
    name: 'CarsPage',
    component: CarsPage
  },
  {
    path: 'post-ride',
    name: 'PostRide',
    component: PostRide
  },
  {
    path: 'register',
    name: 'RegisterPage',
    component: RegisterPage,
  },
  {
    path: 'book-ride/:ride_id',
    name: 'BookRide',
    component: BookRide,
  },
  {
    path: 'book-ride/request/:ride_id',
    name: 'RequestBookRide',
    component: RequestBookRide,
  },
  {
    path: 'search',
    name: 'CarSharingList',
    component: CarSharingList,
  },
  {
    path: 'profile',
    name: 'ProfilePage',
    component: ProfilePage,
  },
  {
    path: 'chat',
    name: 'ChatList',
    component: ChatList,
  },
  {
    path: 'dashboard/rides',
    component: RidesPage,
    name: 'RidesPage'
  },
  {
    path: 'dashboard/booked-rides',
    component: BookedRides,
    name: 'bookedRides'
  },
  {
    path: "chats/:chatId",
    name: "ChatDetail",
    component: ChatDetail
  },

  
  // {
  //   path: '/about',
  //   name: 'AboutComponent',
  //   component: AboutComponent,
  // },
  // {
  //   path: '/services',
  //   name: 'Services',
  //   component: Services,
  // },
  // {
  //   path: '/contact',
  //   name: 'Contact',
  //   component: Contact,
  // },
  ]}
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
