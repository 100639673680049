<template>
  <div class="app-page">
    <div class="edit-driver-infos">
      <header>
        <h1>Modifier les Informations du Conducteur</h1>
      </header>
      <div class="container">
        <form @submit.prevent="updateDriverInfo">

          <div class="form-group">
            <label for="license">Numéro de permis de conduire:</label>
            <input
              type="text"
              id="license"
              v-model="user.license_number"
              required
            />
            <div class="license-status">
            <div v-if="user.licence_status === 'unverified'" class="status unverified">
              <p>Your license is pending verification.</p>
              <!-- <button @click="verifyLicense">Verify License</button> -->
            </div>
            
            <div v-else-if="user.licence_status === 'valid'" class="status valid">
              <p>Your license is valid!</p>
            </div>
            
            <div v-else-if="user.licence_status === 'unvalid'" class="status unvalid">
              <p>Your license is invalid. Please contact support.</p>
              <!-- <button @click="retryVerification">Retry Verification</button> -->
            </div>
          </div>
          </div>

          <div class="form-group">
            <button class="btn btn--primary" type="submit">Sauvegarder</button>
          </div>
        </form>
        <div v-if="message" class="message">{{ message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
// import { useNotification } from "@kyvg/vue3-notification";

export default {
  props: {
      uri: String
    },
  data() {
    return {
      token: Cookies.get('authToken'),
      licenseStatus: 'valid', // initial state
      user: {
        license_number: '',
        licence_status: ''
      },
      message: ''
    };
  },
  methods: {
    async fetchProfile() {
      try {
        const response = await axios.get(this.uri + '/api/users/get', {
          headers: {
            "Authorization": `Bearer ${this.token}`,
          },
        });
        this.user = response?.data?.user;
        this.user.profilePicture = response?.data?.user?.url ? 'https://spawnaride.com/users-pictures/' + response.data.user.url : null;
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    },
    async loadDriverInfo() {
      // Remplacer par l'URL de votre API pour récupérer les informations du conducteur
      const response = await fetch(this.uri + '/api/drivers/me');
      this.driverInfo = await response.json();
    },
    async updateDriverInfo() {
      try {
        // Remplacer par l'URL de votre API pour mettre à jour les informations du conducteur
        const response = await fetch(this.uri + '/api/drivers/update', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.driverInfo)
        });

        if (!response.ok) {
          throw new Error('Échec de la mise à jour des informations');
        }

        this.message = 'Informations mises à jour avec succès!';
      } catch (error) {
        this.message = error.message;
      }
    }
  },
  created() {
    this.loadDriverInfo();
  },
  watch: {
    $route() {
    // Push a new query parameter when the route changes
    this.$router.push({ query: { menuType: 'app' } });
  },
  },
  mounted () {
    this.$router.push({ query: { menuType: 'app' } });
    this.fetchProfile();
  }
};
</script>

<style scoped>
.license-status {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
}

.status {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.unverified {
  background-color: #ffebcc;
  color: #ff9900;
}

.valid {
  background-color: #e6ffcc;
  color: #339933;
}

.unvalid {
  background-color: #ffe6e6;
  color: #cc3333;
}
.edit-driver-infos {
  max-width: 600px;
  margin: 30px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

header {
  background-color: #8ACE00;
  color: white;
  padding: 20px;
  text-align: center;
}

.container {
  margin: 20px auto;
  max-width: 600px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  width: 100%;
}

.message {
  margin-top: 20px;
  color: 8ACE00;
  text-align: center;
}
</style>
