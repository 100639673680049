<template>
  <div class="app-page">
    <div class="terms">
      <header>
      <h1>Politique de Confidentialité</h1>
    </header>
    <div class="container">
      <section class="introduction">
        <h2>Introduction</h2>
        <p>
          Chez Spawnaride Inc., nous prenons la confidentialité de vos données très au sérieux. Cette
          politique de confidentialité décrit comment nous recueillons, utilisons, et protégeons vos
          informations personnelles lorsque vous utilisez notre plateforme.
        </p>
      </section>

      <section class="information-collection">
        <h2>Collecte des Informations</h2>
        <p>
          Nous collectons des informations personnelles que vous nous fournissez directement, telles
          que votre nom, adresse e-mail, numéro de téléphone et autres informations nécessaires à la
          création de votre compte. De plus, nous collectons automatiquement certaines informations
          sur votre appareil et votre utilisation de notre application.
        </p>
      </section>

      <section class="use-of-information">
        <h2>Utilisation des Informations</h2>
        <p>
          Nous utilisons vos informations pour :
        </p>
        <ul>
          <li>Fournir et améliorer notre service</li>
          <li>Gérer votre compte et vous fournir un support client</li>
          <li>Vous envoyer des notifications et des mises à jour</li>
          <li>Analyser l'utilisation de notre plateforme</li>
        </ul>
      </section>

      <section class="data-sharing">
        <h2>Partage des Informations</h2>
        <p>
          Nous ne partageons pas vos informations personnelles avec des tiers, sauf dans les cas
          suivants :
        </p>
        <ul>
          <li>Avec votre consentement</li>
          <li>Pour respecter les lois et règlements applicables</li>
          <li>Pour protéger nos droits et notre propriété</li>
        </ul>
      </section>

      <section class="data-security">
        <h2>Sécurité des Données</h2>
        <p>
          Nous mettons en œuvre des mesures de sécurité pour protéger vos informations personnelles
          contre tout accès non autorisé, divulgation ou destruction. Cependant, aucune méthode de
          transmission sur Internet ou méthode de stockage électronique n'est sécurisée à 100 %.
        </p>
      </section>

      <section class="your-rights">
        <h2>Vos Droits</h2>
        <p>
          Vous avez le droit de :
        </p>
        <ul>
          <li>Accéder à vos informations personnelles que nous détenons</li>
          <li>Demander la rectification de vos informations si elles sont inexactes</li>
          <li>Demander la suppression de vos informations dans certaines circonstances</li>
        </ul>
      </section>

      <section class="changes">
        <h2>Modifications de cette Politique</h2>
        <p>
          Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment.
          Nous vous informerons de tout changement en publiant la nouvelle politique sur cette page.
          Nous vous encourageons à consulter régulièrement cette page pour rester informé de
          l'évolution de notre politique.
        </p>
      </section>

      <section class="contact">
        <h2>Nous Contacter</h2>
        <p>
          Si vous avez des questions concernant cette politique de confidentialité, veuillez nous
          contacter
          <router-link :to="getLocaleLink('/contact-us')">contact</router-link>.
        </p>
      </section>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    uri: String,
    getLocaleLink: Function
  },
  data() {
    return {};
  },
  watch: {
    $route() {
    // Push a new query parameter when the route changes
    this.$router.push({ query: { menuType: 'app' } });
  },
  },
  mounted () {
    this.$router.push({ query: { menuType: 'app' } });
  }
};
</script>

<style scoped>
.terms {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}
</style>
