<template>
    <div class="booked-rides-page">
      <h1>Booked Rides</h1>
      <div v-if="loading" class="loading">
        <p>Loading your booked rides...</p>
      </div>
  
      <div v-if="bookedRides.length > 0" class="rides-list">
        <div
          v-for="ride in bookedRides"
          :key="ride.id"
          class="ride-item"
          @click="goToRideDetails(ride.id)"
        >
          <div class="ride-details">
            <div class="ride-info">
              <p><strong>From:</strong> {{ ride.from }}</p>
              <p><strong>To:</strong> {{ ride.to }}</p>
              <p><strong>Date:</strong> {{ formatDate(ride.date) }}</p>
              <p><strong>Time:</strong> {{ ride.time }}</p>
            </div>
            <div class="ride-status" :class="{ upcoming: ride.isUpcoming }">
              <p v-if="ride.isUpcoming">Upcoming</p>
              <p v-else>Completed</p>
            </div>
          </div>
        </div>
      </div>
  
      <div v-else-if="!loading" class="no-rides">
        <p>You have no booked rides.</p>
      </div>
    </div>
  </template>
  
  <script>
import axios from 'axios';

export default {
  data() {
    return {
      bookedRides: [],
      loading: true,
    };
  },
  props: {
    uri: String
  },
  methods: {
    async fetchBookedRides() {
      try {
        const response = await axios.get(this.uri + '/api/booked-rides', {
          headers: {
            Authorization: `Bearer ${this.getAuthToken()}`, // Ensure user is authenticated
          },
        });
        this.bookedRides = response.data;
      } catch (error) {
        console.error('Error fetching booked rides:', error);
      } finally {
        this.loading = false;
      }
    },
    goToRideDetails(rideId) {
      this.$router.push({ name: 'RideDetails', params: { rideId } });
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    getAuthToken() {
      // Retrieve the token from cookies or localStorage
      return localStorage.getItem('authToken'); // Example: adjust based on your app's authentication
    },
  },
  mounted() {
    this.fetchBookedRides();
  },
};
</script>

<style scoped>
.booked-rides-page {
  padding: 20px;
}

.loading {
  text-align: center;
}

.rides-list {
  display: flex;
  flex-direction: column;
}

.ride-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  cursor: pointer;
}

.ride-details {
  display: flex;
  flex-direction: column;
}

.ride-info p {
  margin: 5px 0;
}

.ride-status p {
  font-weight: bold;
}

.upcoming {
  color: #8ace00;
}

.no-rides {
  text-align: center;
  color: gray;
}
</style>
