<template>
  <div id="app">
    <HeaderView :getLocaleLink="getLocaleLink" v-if='menuType === "default"'/>
    <main>
      <router-view :getLocaleLink="getLocaleLink" :loadUser="loadUser" :user="user" :uri="uri" />
    </main>
    <AppFooter :getLocaleLink="getLocaleLink" :user="user" v-if='menuType !== "default"'/>
    <FooterView v-else :getLocaleLink="getLocaleLink" />
  </div>
  <notifications class="notif" position="top right" />
</template>

<script>
  import axios from 'axios';
  import Cookies from 'js-cookie';
  import HeaderView from './components/HeaderView.vue';
  import FooterView from './components/FooterView.vue';
  import AppFooter from './components/AppFooter.vue';

  export default {
    name: 'App',
    watch: {
      $route () {
        // if(from.params.locale !== to.params.locale)
        this.$i18n.locale = (this.$route.params.locale ? this.$route.params.locale : 'en');
      },
    },
    methods: {
      getLocaleLink(path) {
        return this.$i18n.locale === 'en' ? path : '/' + this.$i18n.locale + path
      },
      async loadUser () {
        await this.fetchProfile();
      },
      async fetchProfile() {
        try {
          const response = await axios.get(this.uri + '/api/users/get', {
            headers: {
              "Authorization": `Bearer ${this.token}`,
            },
          });
          this.user = response?.data?.user;
          this.user.profilePicture = response?.data?.user?.url ? 'https://spawnaride.com/users-pictures/' + response.data.user.url : '/images/blank-profile.png';
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      },
    },
    mounted () {
      if(this.token) this.fetchProfile();
      console.log("--" + process.env.VUE_APP_API_URL);
      this.uri = process.env.VUE_APP_API_URL;
    },
    components: {
      HeaderView,
      FooterView,
      AppFooter
    },
    data () {
      return {
        uri: process.env.VUE_APP_API_URL,
        user: {
          profilePicture: null
        },
        token: Cookies.get('authToken'),
        languages: [
          {"language": "English", "code": "en"},
          {"language": "German", "code": "de"},
          {"language": "Azerbaijani", "code": "az"},
          {"language": "Dutch", "code": "nl"},
          {"language": "French", "code": "fr"},
          {"language": "Portuguese", "code": "pt"},
          {"language": "Bulgarian", "code": "bg"},
          {"language": "Spanish", "code": "es"},
          {"language": "Mandarin", "code": "zh"},
          {"language": "Czech", "code": "cs"},
          {"language": "Danish", "code": "da"},
          {"language": "Greek", "code": "el"},
          {"language": "Hungarian", "code": "hu"},
          {"language": "Hindi", "code": "hi"},
          {"language": "Indonesian", "code": "id"},
          {"language": "Italian", "code": "it"},
          {"language": "Japanese", "code": "ja"},
          {"language": "Korean", "code": "ko"},
          {"language": "Luxembourgish", "code": "lb"},
          {"language": "Malay", "code": "ms"},
          {"language": "Tagalog", "code": "tl"},
          {"language": "Polish", "code": "pl"},
          {"language": "Romanian", "code": "ro"},
          {"language": "Arabic", "code": "ar"},
          {"language": "Tamil", "code": "ta"},
          {"language": "Slovak", "code": "sk"},
          {"language": "Zulu", "code": "zu"},
          {"language": "Xhosa", "code": "xh"},
          {"language": "Afrikaans", "code": "af"},
          {"language": "Swedish", "code": "sv"},
          {"language": "Thai", "code": "th"},
          {"language": "Turkish", "code": "tr"},
          {"language": "Ukrainian", "code": "uk"},
          {"language": "Vietnamese", "code": "vi"},
          {"language": "French", "code": "fr"},
          {"language": "Cantonese", "code": "can"},
          {"language": "Chinese", "code": "zh"}
        ]
      }
    },
    computed: {
      // Access query params or route params
      menuType() {
        return this.$route.query.menuType || 'default'; // Accessing menuType from query
      }
    },
  };
</script>

<style lang="scss">
  @import 'styles/main.scss';
  .notif {
    z-index: 1500000!important;
  }
</style>
