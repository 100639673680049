<template>
  <div class="about">
    <header>
      <h1>À Propos de Spawnaride</h1>
    </header>
    <div class="container">
      <section class="mission">
        <h2>Notre Mission</h2>
        <p>
          Chez Spawnaride Inc., notre mission est de connecter les conducteurs et les passagers d'une manière
          simple et efficace. Nous croyons que chaque trajet peut être une opportunité de créer des
          liens et de réduire l'empreinte carbone en optimisant l'utilisation des ressources.
        </p>
      </section>

      <section class="vision">
        <h2>Notre Vision</h2>
        <p>
          Nous aspirons à devenir le leader dans le domaine du covoiturage, en offrant une plateforme
          intuitive et conviviale qui facilite la mobilité durable. Notre vision est de créer un
          écosystème de transport où chaque trajet compte, en encourageant l'entraide et le partage.
        </p>
      </section>

      <section class="impact">
        <h2>Notre Impact</h2>
        <p>
          En choisissant Spawnaride, vous participez à un mouvement qui vise à réduire le nombre de
          véhicules sur les routes, à diminuer les émissions de CO2 et à promouvoir une culture de
          partage. Chaque utilisateur contribue à la construction d'un avenir plus vert et plus
          durable.
        </p>
      </section>

      <section class="team">
        <h2>Notre Équipe</h2>
        <p>
          L'équipe de Spawnaride est composée de passionnés de technologie et de mobilité, dévoués à
          fournir la meilleure expérience à nos utilisateurs. Nous travaillons sans relâche pour
          améliorer notre plateforme et répondre aux besoins de notre communauté.
        </p>
      </section>

      <section class="contact">
        <h2>Nous Contacter</h2>
        <p>
          Pour toute question ou suggestion, n'hésitez pas à nous contacter
          <router-link :to="getLocaleLink('/contact-us')">contact</router-link>.
        </p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
  props: {
    getLocaleLink: Function
  },
  watch: {
    $route() {
    // Push a new query parameter when the route changes
    this.$router.push({ query: { menuType: 'app' } });
  },
  },
  mounted () {
    this.$router.push({ query: { menuType: 'app' } });
  }
};
</script>

<style scoped>
.about {
  padding: 20px;
  background-color: #f9f9f9;
}

header {
  background-color: #8ACE00;
  color: white;
  padding: 20px;
  text-align: center;
}

.container {
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

section {
  margin-bottom: 20px;
}

h2 {
  color: #8ACE00;
}

a {
  color: #8ACE00;
}
</style>
