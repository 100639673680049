<template>
  <div class="app-page">
    <div class="terms">
      <header>
        <h1>My Car<span v-if="cars?.length>1">s</span></h1>
      </header>
      <div class="car-list">
        <div class="car-list__item" v-for="car in cars" :key="car.id" :value="car.id">          
          <div class="car-list__item__img" :style="'background-image: url(https://spawnaride.com/cars-pictures/' + car.user_id + '/' + car.imageUrl + ')'"></div>
          <div class="car-list__item__infos">
            {{ car.year }} {{ car.make }} {{ car.model }} 
          </div>
          <div class="car-list__item__actions">
            <i @click="editCar(car)" class="fa fa-edit car-list__item__actions__button"></i>
            <i @click="deleteCar(car.id)" class="fa fa-trash car-list__item__actions__button car-list__item__actions__button__delete"></i>
          </div>
        </div>
      </div>
      <div style="display: none">
        {{ ride }}
      </div>
      <div v-if="editCarId" class="edit-ride">
        <div class="car-section__voile"></div>
        <div class="car-section">

              <h3>Edit Your Car</h3>

                <!-- Select Make -->
                <div class="label">
                  <label class="" for="make">Make:</label>
                </div>
                <select v-model="selectedMake" @change="onMakeChange">
                  <option value="">Select Make</option>
                  <option v-for="(models, make) in carData" :key="make" :value="make">{{ make }}</option>
                </select>

                <!-- Select Model -->
                <div class="label">
                  <label class="" for="model">Model:</label>
                </div>
                <select v-model="selectedModel" :disabled="!selectedMake">
                  <option value="">Select Model</option>
                  <option v-for="model in carData[selectedMake]" :key="model" :value="model">{{ model }}</option>
                </select>

                <!-- Select Year -->
                <div class="label">
                  <label class="" for="year">Year:</label>
                </div>
                <select v-model="selectedYear" :disabled="!selectedModel">
                  <option value="">Select Year</option>
                  <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                </select>

                <div class="label">
                  <label class="" for="color">Color:</label>
                </div>
                <select v-model="newCar.color">
                  <option value="">Select Color</option>
                  <option v-for="(color, index) in CarColors" :key="index" :value="color">{{ color }}</option>
                </select>

              <div class="form-group">
                <div class="label">
                  <label class="" for="carPlate">Plate number:</label>
                </div>
                <input v-model="newCar.plate" type="text" placeholder="Enter car plate number" required />
              </div>

              <div class="label">
                <img class="label-picto" src="/images/car-seat-1.png"/>
                <label class="label-label" for="newCapacity">Capacity (max passengers):</label>
              </div>
              <input id='newCapacity' v-model="newCar.capacity" type="text" placeholder="Enter car max passenfers" required />
              <div class="label">
                <img class="label-picto" src="/images/tire.png"/>
                <label for="newWT" class="label-label">Winter Tires?</label>
                <input  class="label-check" id='newWT' v-model="newCar.winter_tires" type="checkbox">
              </div>
              <div class="label">
                <img class="label-picto" src="/images/air-conditioner (1).png"/>
                <label for="newAirConditioning" class="label-label">Air Conditioning?</label>
                <input  class="label-check" id='newAirConditioning' v-model="newCar.air_conditioning" type="checkbox">
              </div>
              <!-- Image upload -->
              <div class="form-group">
                <label for="carImage">Upload Car Image:</label>
                <input type="file" @change="onImageSelected" accept="image/*" />
              </div>

              <!-- Display image preview -->
              <div v-if="newCar.image">
                <h4>Image Preview:</h4>
                <div alt="Profile Picture" class="car-picture" :style="'background-image: url(' + newCar.image + ')'"></div>
              </div>

              <button class="btn btn--primary" type="button" @click="updateCar(newCar.id)">Enregistrer</button>
              <button class="btn" type="button" @click="editCarId=null">Annuler</button>
          </div>
      </div>
    </div>
    <div v-if="isLoading" class="loaging-page"><img class="loaging-page__loader" src="@/assets/images/loader.gif"/></div>
  </div>
</template>


<script>
  import axios from 'axios';
  import "leaflet/dist/leaflet.css";
  import Cookies from 'js-cookie';
  import { useNotification } from "@kyvg/vue3-notification";
  const { notify } = useNotification()

export default {
  props: {
    uri: String
  },
  data() {
    return {
      isLoading: false,
      editCarId: null,
      selectedMake: "",
      selectedModel: "",
      selectedYear: "",
      years: this.generateYears(1990, new Date().getFullYear() + 2), // Array of years from 1990 to current year
      selectedDate: '', // Stores the selected date
      selectedHour: '00', // Default hour
      selectedMinute: '00', // Default minute
      selectedReturnDate: '', // Stores the selected date
      selectedReturnHour: '00', // Default hour
      selectedReturnMinute: '00', // Default minute
      rideImage: null,
      token: Cookies.get('authToken'),
      ride: {
          vehicleId: null,
          seatsAvailable: 3,
          winterTires: false,
          airConditioning: false,
        },
        newCar: {
            id: null,
            brand: '',
            capacity: 3,
            winter_tires: 0,
            air_conditioning: 0,
            model: '',
            year: '',
            color: '',
            plate: '',
            image:  '/images/icon-3286845_640.png',
          },
        "CarColors": [
          "White",
          "Black",
          "Gray",
          "Silver",
          "Red",
          "Blue",
          "Brown",
          "Green",
          "Yellow",
          "Orange",
          "Beige",
          "Gold",
          "Purple",
          "Pink",
          "Burgundy",
          "Turquoise",
          "Navy Blue",
          "Dark Green",
          "Ivory",
          "Charcoal",
          "Bronze",
          "Magenta",
          "Teal",
          "Lavender",
          "Copper",
          "Champagne",
          "Mint Green",
          "Cobalt Blue",
          "Lime Green",
          "Peach",
          "Maroon",
          "Midnight Blue",
          "Crimson",
          "Pearl White",
          "Matte Black",
          "Matte Gray",
          "Gunmetal",
          "Graphite",
          "Electric Blue",
          "Rose Gold",
          "Anthracite",
          "Sunset Orange"
        ],
        carData: {
          "Acura": ["ILX", "MDX", "NSX", "RDX", "TLX", "RLX", "ZDX"],
          "Alfa Romeo": ["Giulia", "Stelvio", "4C", "Tonale", "Mito"],
          "Aston Martin": ["DB11", "DBS", "Vantage", "Rapide", "Vanquish", "Valhalla"],
          "Audi": ["A3", "A4", "A6", "Q3", "Q5", "Q7", "Q8", "e-tron", "RS7", "TT", "R8"],
          "Bentley": ["Bentayga", "Continental", "Flying Spur", "Mulsanne"],
          "BMW": ["2 Series", "3 Series", "4 Series", "5 Series", "7 Series", "X1", "X3", "X5", "X7", "i3", "i8", "Z4", "M4", "M5"],
          "Buick": ["Encore", "Envision", "Enclave", "Regal", "LaCrosse", "Cascada", "Lucerne"],
          "Cadillac": ["Escalade", "XT4", "XT5", "XT6", "CT4", "CT5", "CT6", "ATS", "SRX"],
          "Chevrolet": ["Silverado", "Malibu", "Equinox", "Tahoe", "Traverse", "Blazer", "Camaro", "Corvette", "Trailblazer", "Suburban", "Colorado", "Impala", "Sonic", "Volt"],
          "Chrysler": ["300", "Pacifica", "Voyager", "Aspen"],
          "Dodge": ["Challenger", "Charger", "Durango", "Grand Caravan", "Journey", "Viper", "Dart"],
          "Ferrari": ["488", "Roma", "SF90", "Portofino", "F8 Tributo", "812 Superfast"],
          "Fiat": ["500", "500X", "500L", "Panda", "Tipo"],
          "Ford": ["F-150", "Escape", "Explorer", "Mustang", "Edge", "Expedition", "Bronco", "Ranger", "Maverick", "Fusion", "Fiesta", "Taurus", "EcoSport", "Transit", "Transit Connect"],
          "Genesis": ["G70", "G80", "G90", "GV70", "GV80"],
          "GMC": ["Acadia", "Canyon", "Sierra 1500", "Terrain", "Yukon", "Sierra 2500", "Sierra 3500", "Savana"],
          "Honda": ["Civic", "Accord", "CR-V", "Pilot", "Fit", "Odyssey", "Ridgeline", "HR-V", "Passport", "Insight", "Element", "S2000"],
          "Hyundai": ["Elantra", "Santa Fe", "Tucson", "Sonata", "Kona", "Palisade", "Veloster", "Venue", "Ioniq", "Genesis Coupe"],
          "Infiniti": ["Q50", "Q60", "QX50", "QX60", "QX80", "QX30"],
          "Jaguar": ["F-Pace", "E-Pace", "I-Pace", "XE", "XF", "XJ", "F-Type"],
          "Jeep": ["Cherokee", "Grand Cherokee", "Wrangler", "Compass", "Renegade", "Gladiator", "Patriot"],
          "Kia": ["Forte", "Sorento", "Sportage", "Soul", "Telluride", "Seltos", "Carnival", "K5", "Rio", "Optima", "Stinger"],
          "Lamborghini": ["Huracan", "Aventador", "Urus"],
          "Land Rover": ["Range Rover", "Discovery", "Defender", "Evoque", "Freelander"],
          "Lexus": ["ES", "RX", "NX", "GX", "LS", "LX", "IS", "LC", "RC"],
          "Lincoln": ["Aviator", "Corsair", "Nautilus", "Navigator", "MKC", "MKZ", "Continental", "MKT"],
          "Maserati": ["Ghibli", "Levante", "Quattroporte", "GranTurismo"],
          "Mazda": ["CX-3", "CX-5", "CX-9", "Mazda3", "Mazda6", "MX-5 Miata", "CX-30", "RX-8"],
          "McLaren": ["GT", "720S", "600LT", "570S", "650S", "P1", "Senna"],
          "Mercedes-Benz": ["A-Class", "C-Class", "E-Class", "S-Class", "GLA", "GLC", "GLE", "GLS", "G-Class", "CLA", "SL", "AMG GT", "EQC"],
          "Mini": ["Cooper", "Clubman", "Countryman", "Paceman"],
          "Mitsubishi": ["Eclipse Cross", "Outlander", "Outlander Sport", "Mirage", "Mirage G4", "Lancer", "Montero"],
          "Nissan": ["Altima", "Frontier", "Leaf", "Maxima", "Murano", "Pathfinder", "Rogue", "Sentra", "Titan", "Versa", "Armada", "370Z", "GT-R"],
          "Porsche": ["Cayenne", "Macan", "Panamera", "911", "718", "Taycan", "Boxster"],
          "Ram": ["1500", "2500", "3500", "ProMaster City", "ProMaster"],
          "Rolls-Royce": ["Phantom", "Ghost", "Wraith", "Cullinan", "Dawn"],
          "Subaru": ["Ascent", "Crosstrek", "Forester", "Impreza", "Legacy", "Outback", "WRX", "BRZ"],
          "Tesla": ["Model 3", "Model S", "Model X", "Model Y", "Cybertruck", "Roadster"],
          "Toyota": ["Corolla", "Camry", "RAV4", "Highlander", "Tacoma", "Tundra", "4Runner", "Prius", "Avalon", "Sienna", "Supra", "C-HR"],
          "Volkswagen": ["Atlas", "Golf", "Jetta", "Passat", "Tiguan", "ID.4", "Beetle", "Arteon", "Touareg"],
          "Volvo": ["S60", "S90", "XC40", "XC60", "XC90", "V60", "V90", "C40 Recharge"],
        },

    };
  },
  methods: {
    onImageSelected(event) {
      const file = event.target.files[0];
      if (file) {
        this.carPictureFile = file;
        this.newCar.image = URL.createObjectURL(file); // Show the preview
      }
    },
    generateYears(startYear, endYear) {
      const years = [];
      for (let year = startYear; year <= endYear; year++) {
        years.push(year);
      }
      return years;
    },
    async deleteCar (carId) {
      try {
        this.isLoading = true;
        await axios.delete(this.uri + `/api/cars`, {
          params: { car_id: carId },
          headers: {
            "Authorization": `Bearer ${this.token}`,
          },
        });
        this.fetchCars();
        this.isLoading = false;
        notify({
          title: "Delete car",
          text: "Car deleted successfully!",
        }); 
      } catch (error) {
        this.isLoading = false;
        this.editCarId = null;
        notify({
          title: "Error ",
          text: "There was an error deleting your car infos.",
          type: 'warn',
        });
      }
    },
    editCar (carInfo) {
      this.selectedMake = carInfo.make;
      this.selectedModel = carInfo.model;
      this.selectedYear = carInfo.year;
      this.newCar.color = carInfo.color;
      this.newCar.plate = carInfo.plate_number;
      this.newCar.id = carInfo.id;
      this.newCar.capacity = carInfo.capacity;
      this.newCar.winter_tires = carInfo.winter_tires === 1 ? true : false;
      this.newCar.air_conditioning = carInfo.winter_tires === 1 ? true : false;
      this.newCar.image = carInfo.imageUrl ? 'https://spawnaride.com/cars-pictures/' + carInfo.user_id + '/' + carInfo.imageUrl: null;
      this.editCarId = carInfo.id;
    },
    async updateCar(carId) {
      try {
        // Handle form data for image upload
        this.isLoading = true;
        const formData = new FormData();
        formData.append('car_id', carId);
        formData.append('make', this.selectedMake);
        formData.append('model', this.selectedModel);
        formData.append('year', this.selectedYear);
        formData.append('color', this.newCar.color);
        formData.append('plate', this.newCar.plate);
        formData.append('capacity', this.newCar.capacity);
        formData.append('winter_tires', this.newCar.winter_tires);
        formData.append('air_conditioning', this.newCar.air_conditioning);
        if (this.carPictureFile) {
          formData.append('carPictureFile', this.carPictureFile);
        }

        const response = await axios.put(this.uri + '/api/cars', formData, {
          headers: {
            "Authorization": `Bearer ${this.token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(response);
        this.fetchCars();
        this.isLoading = false;
        this.editCarId = null;
        notify({
          title: "Saved",
          text: "Car infos updated successfully!",
        });
      } catch (error) {
        this.isLoading = false;
        this.editCarId = null;
        notify({
          title: "Error ",
          text: "There was an error updating your car infos.",
          type: 'warn',
        });
      }
    },
    async fetchCars(car_id = null) {
        try {
          const response = await axios.get(this.uri + '/api/cars/list', {
            headers: {
              "Authorization": `Bearer ${this.token}`,
            },
          });
          this.cars = response?.data;
          if(this.cars.length) {
            const carId = car_id ? car_id : this.cars[0].id;
            const carInfo = this.cars.find(car => car.id === carId);

            this.showAddCar = true;
            
            this.ride = {
              vehicleId:carId
            }
            
            this.ride.seatsAvailable = carInfo.capacity;
            this.ride.airConditioning = carInfo.air_conditioning === 1 ? true : false;
            this.ride.winterTires = carInfo.winter_tires === 1 ? true : false;
            this.rideImage = carInfo.imageUrl ? 'https://spawnaride.com/cars-pictures/' + carInfo.user_id + '/' + carInfo.imageUrl: null;
            this.selectedMake = carInfo.make;
            this.selectedModel = carInfo.model;
            this.selectedYear = carInfo.year;
            this.newCar.color = carInfo.color;
            this.newCar.plate = carInfo.plate_number;
            this.newCar.capacity = carInfo.capacity;
            this.newCar.winter_tires = carInfo.winter_tires === 1 ? true : false;
            this.newCar.air_conditioning = carInfo.winter_tires === 1 ? true : false;
            this.newCar.image = carInfo.imageUrl ? 'https://spawnaride.com/cars-pictures/' + carInfo.user_id + '/' + carInfo.imageUrl: null;

          }
          // this.user.profilePicture = response?.data?.user?.url ? 'https://spawnaride.com/users-pictures/' + response.data.user.url : null;
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      },
  },
  watch: {
    $route() {
    // Push a new query parameter when the route changes
    this.$router.push({ query: { menuType: 'app' } });
  },
  },
  mounted () {
    this.$router.push({ query: { menuType: 'app' } });
    this.fetchCars();
  }
};
</script>

<style scoped>
   .loaging-page {
    background-color: rgba(0,0,0,.7);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10000;
   }
   .loaging-page__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
   }
   .car-picture, .car-image {
    background-color: #111;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 150px;
    height: 150px;
    border-radius: 150px;
    margin-bottom: 20px;
  }
  .edit-ride {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10000;
    overflow-y: scroll;
  }
  .car-section__voile {
    background: rgba(0, 0, 0, .7);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
  }
  input, select, textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  } 
  .btn {
    width: 100%;
  }
.label {
    background: #eee;
    border: 1px solid #ddd;
    padding: 5px;
    position: relative;
    height: 43px;
  }
  .label-picto {
    width: 52px;
    margin: 0 10px 0 0;
    width: 42px;
    padding: 5px;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .label-label {
    position: absolute;
    left: 50px;
    top: 0;
    border: 0px;
    background: transparent;
    top: 50%;
    transform: translate(0, -50%);
  }
  .label-check {
    accent-color: #8ace00;
    height: 21px;
    width: 21px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0 0 0 20px;
    right: 10px;
  }
  .add-car-section {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .car-image-preview {
    width: 150px;
    height: auto;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  textarea {
    height: 100px;
  }
  .suggestions {
    list-style-type: none;
    padding: 0;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  .time-picker {
    display: flex;
    align-items: center;
  }
  .time-picker select {
    margin-left: 10px;
  }
 .car-section {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-width: 600px;
    width: 100;
    position: relative;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #fff;
  }
  .car-image-preview {
    width: 150px;
    height: auto;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  .terms {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  .label {
    background: #eee;
    border: 1px solid #ddd;
    padding: 5px;
    position: relative;
    height: 43px;
  }
  .label-picto {
    width: 52px;
    margin: 0 10px 0 0;
    width: 42px;
    padding: 5px;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .label-label {
    position: absolute;
    left: 50px;
    top: 0;
    border: 0px;
    background: transparent;
    top: 50%;
    transform: translate(0, -50%);
  }
  .car-list {
    width: 100%;
    display: inline-block;
  }
  .car-list__item {
    width: 100%;
    display: block;
    float: left;
    border: 1px solid #d6d6d6;
    border-radius: 10px;
    margin: 0 0 10px;
    padding: 10px;
    background-color: #fff;
    transition: all .5s ease;
  }
  .car-list__item:hover {
    background-color: #f3ffdc;
  }
  .car-list__item__img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    float: left;
  }
  .car-list__item__infos {
    float: left;
    width: calc(100% - 200px);
    font-size: 14px;
    padding: 10px 10px;
  }

  .car-list__item__actions {
    float: left;
    width: 100px;
  }
  .car-list__item__actions__button {
    font-size: 20px;
    padding: 10px;
    border-radius: 10px;
    background-color: #8ace00;
    color: #111;
    margin: 10px 4px;
    transition: all .5s ease;
    cursor: pointer;
  }
  .car-list__item__actions__button:hover {
    background-color: #aaff00;
  }
  /* .edit-ride {
    display: none;
  } */

  .car-list__item__actions__button__delete {
    background-color: #ff6767;
  }
  .car-list__item__actions__button__delete:hover {
    background-color: #ff5100;
  }
</style>
