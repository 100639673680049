<template>
    <div class="app-page">

        <div class="book-ride">
            <div v-if="loaded">
              <div class="left-side-page">
                <div class="driver-info">
                  <div :style="'background-image: url(https://spawnaride.com/users-pictures/' + ride_infos[0].url + ');'" class="driver-picture"></div>
                  <div class="driver-details">
                    <h3 v-text="ride_infos[0]?.name || ''"></h3>
                    <p class="rating"><span>★</span> {{ ride_infos[0]?.driverRating || $t('Not rated yet') }} - {{ $t('Driven') }}: {{ ride_infos[0]?.driven || '0' }}</p>
                  </div>
                </div>
                <div class="ride-details">

                  <p class="ride-details__title">{{ initial_segment[0]?.il_city || '' }} {{ $t('to') }} {{ final_segment[0]?.fl_city || '' }}</p>   
                  <p class="departure-time departure-time__warning" v-if="initial_segment[0].il_id !== final_segment[0].il_id">
                    {{ $t('Estimated Departure') }}: {{ calculateStopDepartureTime(ride_infos[0].departure_datetime, calculateDurationToStart(ride_infos[0].fareData,initial_segment[0].il_city, final_segment[0].fl_city)) }}<br>
                    <span>{{ $t("Please reach out to the driver to confirm the exact departure time from this stop.") }}</span>
                  </p>
                  <p v-else class="departure-time">
                    <span>{{ $t('Leaving') }}:</span> {{ calculateStopDepartureTime(ride_infos[0].departure_datetime, 0) }}
                  </p>
                  <p class="departure-time">
                    <span>{{ $t('Duration') }}:</span> {{ formatTimeFromSeconds(calculateTotalDuration(ride_infos[0].fareData,initial_segment[0].il_city, final_segment[0].fl_city)) }}
                  </p>

                  <p class="departure-time">
                    <span>{{ $t('Pickup') }}:</span> {{ formatAddress('il', initial_segment[0] )}}
                  </p>

                  <p class="departure-time">
                    <span>{{ $t('Dropoff') }}:</span> {{ formatAddress('fl', final_segment[0] )}}
                  </p>
                  <div class="fare-info">
                    <p class="fare-info__seats">{{ ride_infos[0].seats_available }} {{ $t(ride_infos[0].seats_available > 1 ? 'Seats Left' : 'Seat Left') }} left -</p>
                    <p class="fare-info__fare">${{ matchedFare(ride_infos[0].fareData,initial_segment[0].il_city, final_segment[0].fl_city)?.fare }} {{ $t('per seat') }}</p>
                  </div>
                  <!-- <p v-if="(parseInt(result.segment_level) - 1)">Stops: {{result.segment_level - 1}}</p> -->
                  <p v-if="ride_infos[0]?.notes">notes: {{ride_infos[0].notes}}</p>
                  <router-link :to="getLocaleLink(isLoged() ? '/book-ride/request/' + ride_id +'/?is=' + initial_segment_id + '&s=' + segment_id : '/login?next=' + '/book-ride/request/' + ride_id +'/?is=' + initial_segment_id + '&s=' + segment_id)" class="request-book btn btn--primary">
                    <i class="fas fa-touch icon"></i>
                    {{ $t('Request to book') }}
                  </router-link>
                  <router-link :to="getLocaleLink(isLoged() ? '/chats/' + ride_infos[0].user_id + '?ride_id=' + ride_id +'&is=' + initial_segment_id + '&s=' + segment_id : '/login?next=' + '/chats/' + ride_infos[0].user_id + '?ride_id=' + ride_id +'&is=' + initial_segment_id + '&s=' + segment_id)" class="btn message-btn">
                    <i class="fas fa-comments icon"></i>
                    {{ $t('Message') }} 
                  </router-link>
                </div>
              </div>
             <div class="right-side-page">
              <div class="car-info">
                <div class="car-picture" :style="'background-image:url(' + 'https://spawnaride.com/cars-pictures/' + ride_infos[0].user_id + '/' + ride_infos[0].imageUrl + ')'"></div>
                  <p class="car-make">
                    {{ ride_infos[0].make }} {{ ride_infos[0].model }} {{ ride_infos[0].year }}{{ ride_infos[0]?.color ? " - " : '' }}{{ ride_infos[0].color }}
                  </p>
                  <p>{{  $t('Baggage Size') }}: {{ $t(ride_infos[0].baggage_type) }}</p>
                  <div class="car-info__picto" v-if="ride_infos[0].winter_tires">
                    <img alt="Winter Tires" class="label-picto" src="/images/tire.png"/>
                  </div>
                  <div class="car-info__picto" v-if="ride_infos[0].bicycles">
                    <img class="label-picto" src="/images/drive.png"/>
                  </div>
                  <div class="car-info__picto" v-if="ride_infos[0].snowboard">
                    <img class="label-picto" src="/images/skii.png"/>
                  </div>
                  <div class="car-info__picto" v-if="ride_infos[0].animals">
                    <img class="label-picto" src="/images/paws.png"/>
                  </div>
                  <div class="car-info__picto" v-if="ride_infos[0].air_conditioning">
                    <img class="label-picto" src="/images/air-conditioner (1).png"/>
                  </div>
                  <div class="car-info__picto" v-if="ride_infos[0].smoking_allowed">
                    <img class="label-picto" src="/images/smoking.png"/>
                  </div>
                </div>

              </div>  
              <div class="bottom-page">
                      <!-- Map -->
                      <l-map ref="map" :zoom="zoom" :center="center" style="pointer-events: none; height: 400px; width: 100%">
                        <l-tile-layer :url="url" />
                        <l-marker v-if="startLocationCoords" :lat-lng="startLocationCoords" />
                        <l-marker v-if="endLocationCoords" :lat-lng="endLocationCoords" />
                        <l-marker v-for="(stop, index) in stopCoords" :key="index" :lat-lng="stop"/>
                        <l-polyline v-if="routeCoords.length > 0" :lat-lngs="routeCoords" color="blue" />
                      </l-map>
              </div>            
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Cookies from 'js-cookie';
  import { LMap, LTileLayer, LMarker, LPolyline } from "@vue-leaflet/vue-leaflet";
  // import { nextTick } from 'vue';
  export default {
    name: 'BookRide',
    props: {
      uri: String,
      getLocaleLink: Function
    },
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LPolyline,
    },
    async mounted () {
      this.ride_id = this.$route.params.ride_id;
      this.initial_segment_id = this.$route.query.is;
      this.segment_id = this.$route.query.s;
      this.getRideInfos();
      if(this.isLoged()) this.$router.push({ query: { ...this.$route.query, menuType: 'app' } });
      
    },
    data() {
      return {
        zoom: 6,
        startLocationCoords: null,
        endLocationCoords: null,
        center: [45.5019535, -73.5710061], // Montreal
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        stopCoords: [],
        routeCoords: [],
        ride_infos: [] || {},
        initial_segment: [] || {},
        final_segment: [] || {},
        token: Cookies.get('authToken'),
        name: '',
        pickup: '',
        destination: '',
        pickupTime: '',
        ride_id: null, // Receives the id parameter from the route
        initial_segment_id: null,
        segment_id: null,
        loaded: false,
        seatConfigType: 'sedan'
      };
    },
    methods: {
      isLoged () {
        console.log(this.token);
        return this.token;
      },
      formatAddress(prefix, location) {
        const houseNumber = location[`${prefix}_house_number`];
        const street = location[`${prefix}_street`];
        const city = location[`${prefix}_city`];
        const postalCode = location[`${prefix}_postalcode`];
        const province = location[`${prefix}_province`];
        const country = location[`${prefix}_country`];

        // Combine house number and street name if both exist
        const addressLine1 = houseNumber && street ? `${houseNumber} ${street}` : street || houseNumber || "";

        // Assemble other address parts dynamically
        const addressParts = [addressLine1, city, province, postalCode, country].filter(Boolean);

        // Join the parts into a nicely formatted address
        return addressParts.join(", ");
      },
      async getRoute () {
      let osrmUrl = '';
      osrmUrl = `https://router.project-osrm.org/route/v1/driving/${this.startLocationCoords[1]},${this.startLocationCoords[0]};${this.endLocationCoords[1]},${this.endLocationCoords[0]}?overview=full&geometries=geojson`;
      const response = await axios.get(osrmUrl);

      const route = response.data.routes[0];
        this.distance=route.distance / 1000;
        this.duration=route.duration / 60;

        this.routeCoords = route.geometry.coordinates.map(coord => [coord[1], coord[0]]); // Swap [lon, lat] to [lat, lon]

    
    },
      matchedFare (result, fromPlace, toPlace) {
        if (typeof result === 'string') {
          result = JSON.parse(result);
        }
        const matchedObject = result.find(route => route.from === fromPlace && route.to === toPlace);
        return matchedObject;
      },
      openDatepicker() {
        const dateCalendar = document.getElementById('dateFrom');
        dateCalendar.click();
      },
      calculateDurationToStart(routes, startCity) {
        routes = JSON.parse(JSON.stringify(routes));
          if (!Array.isArray(routes)) {
              console.error("Expected 'routes' to be an array, but got:", routes);
              throw new TypeError("Invalid input: 'routes' must be an array.");
          }
          let totalDuration = 0;
          let currentCity = routes[0].from; // First city is always routes[0].from

          while (currentCity !== startCity) {

              const nextRoute = routes.find(route => route.from === currentCity);
              if (!nextRoute) {
                  throw new Error(`No route found from ${currentCity} to reach ${startCity}`);
              }

              totalDuration += nextRoute.duration;
              currentCity = nextRoute.to;

              if (currentCity === startCity) break;

              if (!routes.some(route => route.from === currentCity)) {
                  throw new Error(`No further routes available from ${currentCity} to reach ${startCity}`);
              }
          }

          return totalDuration;
      },
      calculateTotalDuration(routes, startCity, endCity) {
        let totalDuration = 0;
        let currentCity = startCity;
        routes = JSON.parse(routes);

        while (currentCity !== endCity) {
            const nextRoute = routes.find(route => route.from === currentCity);
            if (!nextRoute) {
                throw new Error(`No route found from ${currentCity} to ${endCity}`);
            }

            totalDuration += nextRoute.duration;
            currentCity = nextRoute.to;

            if (currentCity === endCity) break;


            if (!routes.some(route => route.from === currentCity)) {
                  throw new Error(`No further routes available from ${currentCity} to reach ${endCity}`);
              }
          }

          return totalDuration;
      },
      formatTimeFromSeconds(seconds) {
          const hours = Math.floor(seconds / 3600);
          const minutes = Math.floor((seconds % 3600) / 60);
          
          return `${hours} hour${hours !== 1 ? 's' : ''}, ${minutes} minute${minutes !== 1 ? 's' : ''}`;
      },
      calculateStopDepartureTime(departure_datetime, durationInSeconds) {

        durationInSeconds = durationInSeconds || 0;
        console.log(departure_datetime);
        console.log(departure_datetime);
        // Convert the departure datetime string to a Date object
        let departureDate = new Date(departure_datetime);
        
        // Add the duration (in seconds) to the departure time
        departureDate.setSeconds(departureDate.getSeconds() + durationInSeconds);
        
        // Format the result as a human-readable string
        let stopDepartureTime = departureDate.toLocaleString("en-US", {
          weekday: 'short', // "Mon", "Tue", etc.
          year: 'numeric',
          month: 'short', // "Nov"
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric', // Include seconds
          hour12: true // "AM/PM"
        });
        
        return stopDepartureTime;
      },
      async getRideInfos () {
        let resp = await axios({
          method: 'get',
          url: this.uri + '/api/rides/details?ride_id=' + this.ride_id,
          headers: { 
            'Content-Type': 'application/json'
          }
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
        this.ride_infos = resp;

        resp = await axios({
          method: 'get',
          url: this.uri + '/api/rides/segment?ride_segment_id=' + this.initial_segment_id,
          headers: { 
            'Content-Type': 'application/json'
          }
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
        this.initial_segment = resp;

        resp = await axios({
          method: 'get',
          url: this.uri + '/api/rides/segment?ride_segment_id=' + this.segment_id,
          headers: { 
            'Content-Type': 'application/json'
          }
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
        this.final_segment = resp;
        this.startLocationCoords = [this.initial_segment[0].il_latitude, this.initial_segment[0].il_longitude];
        this.endLocationCoords = [this.final_segment[0].fl_latitude,this.final_segment[0].fl_longitude];
        this.center = [
                        (parseInt(this.initial_segment[0].il_latitude) + parseInt(this.final_segment[0].fl_latitude))/2, 
                        (parseInt(this.initial_segment[0].il_longitude) + parseInt(this.final_segment[0].fl_longitude))/2
                      ];
        this.getRoute();
        this.loaded = true;
        
      },
      submitBooking() {
        // Handle the booking submission
        const bookingDetails = {
          name: this.name,
          pickup: this.pickup,
          destination: this.destination,
          pickupTime: this.pickupTime,
          vehicleId: this.id,
        };
        // Here you would typically make an API call to save the booking
        console.log('Booking Details:', bookingDetails);
      },
    },
  };
  </script>
  
  <style scoped>
  h1 { 
    margin: 70px 0 0!important;
  }
  h2 {
    padding: 0;
    margin: 10px 0 5px;
    font-size: 22px;
    font-weight: 600;
  }
  .bottom-page {
    width: 100%;  
  }
  .bottom-page__left {
    width: calc(60%);
    float:left;
  }
  .bottom-page__right {
    width: calc(40%);
    float:left;
  }
  .book-ride {
    max-width: 1200px!important;
    margin: auto;
    padding: 70px 20px 20px 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    display: grid;
  }
  .datepicker-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-group {
  display: flex;
  align-items: center;
  position: relative;
}

.form-control {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.icon-wrapper {
  cursor: pointer;
  color: #8ace00;
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0,-50%);
} 
.flatpickr-day.startRange,  
.flatpickr-day.endRange {
  background: #FF5722 !important; /* Orange for start/end of range */
  color: #fff !important;
}
.flatpickr-day.today {
  background: #8ace00 !important; /* Blue background for today */
  color: #fff !important;
}
.flatpickr-day.today.selected {
    background: #8ace00;
}
.icon-wrapper:hover {
  color: #75af00;
  font-size: 30px;
}

.fas.fa-calendar-alt {
  font-size: 16px;
}


  .loaging-page__loader {
    width: 46px;
  }
  .results {
  margin-top: 20px;
  padding: 10px;
}
.search-result {

}
.results-grid {

}
.car-info__picto {
  border: 1px solid #8ace00;
  display: block;
  float: left;
  padding: 3px;
  border-radius: 10px;
  margin: 0 3px 0;
  background: #f5f5f5;
  height: 45px;
}
.result-card {
  color:initial;

  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s;
  width: 100%;
  display: flex;
  margin: 0 0 15px;
}
.result-card:hover {
  color:initial;
}
/* .result-card:hover {
  transform: scale(1.02);
} */
.left-side-page{
  width: calc(60%);
  display: inline-block;
  float: left;
}
.right-side-page{
  width: calc(40%);
  display: inline-block;
  float: left;
}
.request-book  {
  margin: 15px 0 0;
  width: 270px;
}
.message-btn {
  margin: 15px 0 0 15px;
}
.seats-config {
  max-width: 100%;
  width: 200px;
  float: left;
  position: relative;
  padding: 0;
  margin: 20px 0 0;
}
.seats-config__sedan {
  width: 100%;
  position: relative;
  z-index: 10;
  opacity: .7;

}
.seats-config__sedan__seat-1 {
  width: calc(100%/3.5);
  position: absolute;
  top: 30%;
  left: 12%;
  opacity: .9;
}
.seats-config__sedan__seat-1__user {
  top: 38%;
  left: calc(14%);
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
}
.seats-config__sedan__seat-2 {
  width: calc(100%/3.5);
  position: absolute;
  top: 30%;
  right: 12%;
  opacity: .9;
}

.seats-config__sedan__seat-3 {
  width: calc(100%/3.5);
  position: absolute;
  top: 60%;
  left: 12%;
  opacity: .9;
}

.seats-config__sedan__seat-4 {
  width: calc(100%/3.5);
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: .9;
  
}

.seats-config__sedan__seat-5 {
  width: calc(100%/3.5);
  position: absolute;
  top: 60%;
  right: 12%;
  opacity: .9;
}
.seats-config__van {
  width: 100%;
  position: relative;
  z-index: 10;
  opacity: .7;
}
.seats-config__van__seat-1 {
  width: calc(100%/4);
  position: absolute;
  top: 27%;
  left: 15%;
  opacity: .9;
  z-index: 8;
}
.seats-config__van__seat-1__user {
  top: 35%;
  left: calc(16%);
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
  z-index: 9;
}
.seats-config__van__seat-2 {
  width: calc(100%/4);
  position: absolute;
  top: 27%;
  right: 15%;
  opacity: .9;
  z-index: 8;
}

.seats-config__van__seat-3 {
  width: calc(100%/4);
  position: absolute;
  top: 45%;
  left: 15%;
  opacity: .9;
}

.seats-config__van__seat-4 {
  width: calc(100%/4);
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: .9;
  
}

.seats-config__van__seat-5 {
  width: calc(100%/4);
  position: absolute;
  top: 45%;
  right: 15%;
  opacity: .9;

}
.seats-config__van__seat-6 {
  width: calc(100%/4);
  position: absolute;
  top: 65%;
  left: 15%;
  opacity: .9;
}

.seats-config__van__seat-7 {
  width: calc(100%/4);
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: .9;
  
}

.seats-config__van__seat-8 {
  width: calc(100%/4);
  position: absolute;
  top: 65%;
  right: 15%;
  opacity: .9;

}

.seats-config__convertible {
  width: 100%;
  position: relative;
  z-index: 10;
  opacity: .7;
}
.seats-config__convertible__seat-1 {
  width: calc(100%/3);
  position: absolute;
  top: 35%;
  left: 15%;
  opacity: .9;
  z-index: 8;
}
.seats-config__convertible__seat-1__user {
  top: 45%;
  left: calc(16%);
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
  z-index: 9;
}
.seats-config__convertible__seat-2 {
  width: calc(100%/3);
  position: absolute;
  top: 35%;
  right: 15%;
  opacity: .9;
  z-index: 8;
}
.car-info {
    padding: 10px;
    width: 100%;
    float: left;
    position: relative;
}
.fare-info {
  padding: 10px 0;
  width: 100%;
  float: left;
  position: relative;
  text-align: right;
}
.fare-info__fare {
  color: #8ace00;
  font-size: 26px;
  font-weight: 700;
  float: left;
  margin: -7px 0 0 0;
  padding: 0 0 0 5px;
}
.fare-info__seats {
  font-weight: 700;
  float: left;
  
}
.car-picture {
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center center;
  border-radius: 20px;
}
.car-make {
  color: #111;
  font-weight: 700;
}
.driver-info {
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  width: 100%;
  float: left;
  position: relative;
}
.driver-info:after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 1px;
    height: 80%;
    background: #bebebe;
    content: none;
}
.driver-picture {
  width: 90px;
  height: 90px;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  margin-right: 15px;
  position: relative;
  float: left;

}

.driver-details {
  float: left;
  display: block;
}
.driver-details h3 {
  margin: 10px 0 0;
  font-size: 23px;
  text-align: left;
  font-weight: 700;
}
.rating {
  text-align: center;
  padding: 0 0 0 40px;
  position: relative;
}
.rating span {
  color: rgb(230, 214, 0);
  font-size: 32px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0,-50%);
}
.driven {
  margin: 0;
  padding: 0;
  text-align: center;
}
.ride-details {
  padding: 20px;
  width: calc(100%);
  float: left;
  position: relative;
}
.ride-details__title {
  color: #111;
  font-weight: 700;
  font-size: 21px!important;
}
.departure-time {
  font-weight: 700;
}
.departure-time__warning span {
  color: rgb(255, 102, 0);
}
.ride-details:after {
  position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: 80%;
    background: #bebebe;
    content: none;
}
.car-info:after {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(0, -50%);
    width: 1px;
    height: 100%;
    background: #bebebe;
    content: none;
}
.ride-details p {
  margin: 5px 0;
  font-size: 18px;
  margin: 0;
}
.ride-details span {
  color: #111;
}
  .title {
    text-align: center;
    color: #333;
  }
  .result-title {
    margin: 0 0 20px;
    padding: 0;
  }
  .search-form {
    width: 100%;
    display: inline-block;
    background: rgb(228, 228, 228);
    border-radius: 10px;
    margin: 0;
    padding: 0px;
    height: auto;
    min-height: 0;
  }
  
  .form-group {
  }
  .form-group__from {
    width: calc((100% - 400px) / 2);
    float: left;
    display: inline-block;
    margin: 0 10px 0 10px;

  }
  .form-group__from__input-container {
    width: 100%;
    position: relative;
    padding: 0;
    margin: 10px 0 10px 0;
  }
  .form-group__from__input {
    position: relative;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
  }
  .form-group__from__input__close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    padding: 20px 15px;
    font-size: 20px;
  }
  .form-group__switch {
    width: 60px;
    float: left;
    display: inline-block;
    margin: 15px 10px 0 20px;
    border-radius: 10px;
    padding: 10px 20px 0px 10px;
    margin: 15px 0;
  }
  .form-group__to {
    width: calc((100% - 400px) / 2);
    float: left;
    display: inline-block;
    margin: 0 10px 0 0;
  }
  .form-group__when {
    width: 140px;
    float: left;
    display: inline-block;
    margin: 0 20px 0 0;
    height: auto;
    position: relative;
  }
  .form-group__when input {
    padding: 20px 10px;
    font-size: 16px;
    width: 140px;
    margin: 10px 0;
  }
  .form-group__button {
    width: 140px;
    float: right;
    margin: 13px 10px 0 0;
    height: auto;
    padding: 1px 0!important;
    display: inline-block;
    border-radius: 10px!important;
  }
  .input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
  }
  ::placeholder {
      color: #000000;
      opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder { /* Edge 12 -18 */
      color: #000000;
    }
  
  .suggestions {
    list-style-type: none;
    padding: 0;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    position: absolute
  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  
  .button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #8ACE00;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: #111;
  }
  
  .button:hover {
    background-color: #8ACE00;
  }
  
  .results {
    margin-top: 20px;
  }
  
  .results-list {
    list-style-type: none;
    padding: 0;
  }
  
  .result-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .result-item:last-child {
    border-bottom: none;
  }
  .leaflet-container {
    border-radius: 20px;
  }
  @media only screen and (max-width:800px) {
    .request-book, .message-btn {
      width: 100%;
      margin: 15px 0 0 0;
    }
    .departure-time {
      font-size: 16px!important;
    }
    h1 {
      font-size: 18px;
    }
    .driver-picture {
      width: 75px;
      height: 75px;
      margin-top: 7px;
    }
    .car-picture {
      height: 150px;
    }
    .seats-config {
        left: 50%;
        transform: translate(-50%, 0);
    }
    .leaflet-container {
      height: 230px !important;
    }
    .bottom-page__left {
      width: 100%;
    }
    .bottom-page__right {
      width: 100%;
    }
    .left-side-page {
      width: 100%;
    }
    .right-side-page {
      width: 100%;
    }
    .search-form {
      position: relative;
      padding: 10px 0;
    }
    .form-group {
      width: calc(100% - 20px)!important;
      margin: 0 10px;
    }
    .form-group__from__input-container {
      margin: 0;
    }
    .form-group__switch {
      position: absolute;
      right: 90px;
      background: rgb(204 204 204);
      padding: 2px;
      top: 36px;
      z-index: 10;
      padding: 4px;
      transform: rotate(90deg);
      width: 42px;
      height: 42px;
    }
    .form-group__switch img {
      margin: 0;
      padding: 0;
      background-color: #444444;
      border-radius: 7px;
      padding: 5px;
      width: 100px;
    }
    .form-group__when {
      width: calc(100% - 20px)!important;
      margin: 0 10px;
    }
    #dateFrom {
      margin: 0;
      width: 100%;
    }
    .fare-info {
      margin: 0;
      width: 100%;
    }
    .button.form-group__button {
      width: calc(100% - 20px);
    }
    .driver-info {
      width: 100%;
    }
    .ride-details {
      width: 100%;
    }
    .car-info {
      width: 100%;
    }
    .result-card {
      display: block;
    }
    .form-group__when {
      
    }
    /* .icon-wrapper {
      display: none;
    } */
     
  }
  </style>
  