import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './styles/main.scss'; // Import your SCSS file
import Notifications from '@kyvg/vue3-notification'

import { createI18n } from 'vue-i18n'

import can from '@/locales/can.json'
import en from '@/locales/en.json'
import es from '@/locales/es.json'
import fr from '@/locales/fr.json'
import ja from '@/locales/ja.json'
import tl from '@/locales/tl.json'
import zh from '@/locales/zh.json'
import it from '@/locales/it.json'
import de from '@/locales/de.json'

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    es,
    fr,
    ja,
    can,
    tl,
    zh,
    it,
    de
  }
})

createApp(App)
  .use(i18n)
  .use(Notifications)
  .use(router)
  .mount('#app');
