<template>
    <div class="app-page">

        <div class="book-ride">

            <div v-if="loaded">
              <div class="left-side-page">
                <div class="driver-info">
                  <div :style="'background-image: url(https://spawnaride.com/users-pictures/' + ride_infos[0].url + ');'" class="driver-picture"></div>
                  <div class="driver-details">
                    <h3 v-text="ride_infos[0]?.name || ''"></h3>
                    <p class="rating"><span>★</span> {{ ride_infos[0]?.driverRating || 'Not rated yet'}} - Driven: {{ ride_infos[0]?.driven || '0' }}</p>
                  </div>
                </div>
                <div class="ride-details">
                  <h1 v-if="loaded">{{ $t("You're booking a ride with") }} {{ ride_infos[0]?.name }}</h1>

                  <p class="ride-details__title">{{ initial_segment[0]?.il_city || '' }} {{ $t('to') }} {{ final_segment[0]?.fl_city || '' }}</p>   
                  <p class="departure-time departure-time__warning" v-if="initial_segment[0].il_id !== final_segment[0].il_id">
                    {{ $t('Estimated Departure') }}: {{ calculateStopDepartureTime(ride_infos[0].departure_datetime, calculateDurationToStart(ride_infos[0].fareData,initial_segment[0].il_city, final_segment[0].fl_city)) }}<br>
                    <span>{{ $t("Please reach out to the driver to confirm the exact departure time from this stop.") }}</span>
                  </p>
                  <p v-else class="ride-details__title">
                    {{ calculateStopDepartureTime(ride_infos[0].departure_datetime, 0) }}
                  </p>


                  <p class="departure-time">
                    <span>{{ $t('Pickup') }}:</span> {{ formatAddress('il', initial_segment[0] )}}
                  </p>

                  <p class="departure-time">
                    <span>{{ $t('Dropoff') }}:</span> {{ formatAddress('fl', final_segment[0] )}}
                  </p>

                  <p class="departure-time">
                    <span>{{ $t('Duration') }}:</span> {{ formatTimeFromSeconds(calculateTotalDuration(ride_infos[0].fareData,initial_segment[0].il_city, final_segment[0].fl_city)) }}
                  </p>
                  <!-- <p v-if="(parseInt(result.segment_level) - 1)">Stops: {{result.segment_level - 1}}</p> -->
                  <p v-if="ride_infos[0]?.notes">notes: {{ride_infos[0].notes}}</p>
                      <!-- Map -->
                      <l-map ref="map" :zoom="zoom" :center="center" style="border-radius:20px; pointer-events: none; height: 200px; width: 100%">
                        <l-tile-layer :url="url" />
                        <l-marker v-if="startLocationCoords" :lat-lng="startLocationCoords" />
                        <l-marker v-if="endLocationCoords" :lat-lng="endLocationCoords" />
                        <l-marker v-for="(stop, index) in stopCoords" :key="index" :lat-lng="stop"/>
                        <l-polyline v-if="routeCoords.length > 0" :lat-lngs="routeCoords" color="blue" />
                      </l-map>
                </div>

                <div class="car-info">
                  <div class="car-picture" :style="'background-image:url(' + 'https://spawnaride.com/cars-pictures/' + ride_infos[0].user_id + '/' + ride_infos[0].imageUrl + ')'"></div>
                  <div class="car-info__infos">
                    <p class="">
                      {{ ride_infos[0].make }} {{ ride_infos[0].model }} {{ ride_infos[0].year }}
                    </p>
                    <p>{{ $t('Baggage Size') }}: {{ $t(ride_infos[0].baggage_type) }}</p>
                    <div class="car-info__picto" v-if="ride_infos[0].winter_tires">
                      <img alt="Winter Tires" class="label-picto" src="/images/tire.png"/>
                    </div>
                    <div class="car-info__picto" v-if="ride_infos[0].bicycles">
                      <img class="label-picto" src="/images/drive.png"/>
                    </div>
                    <div class="car-info__picto" v-if="ride_infos[0].snowboard">
                      <img class="label-picto" src="/images/skii.png"/>
                    </div>
                    <div class="car-info__picto" v-if="ride_infos[0].animals">
                      <img class="label-picto" src="/images/paws.png"/>
                    </div>
                    <div class="car-info__picto" v-if="ride_infos[0].air_conditioning">
                      <img class="label-picto" src="/images/air-conditioner (1).png"/>
                    </div>
                    <div class="car-info__picto" v-if="ride_infos[0].smoking_allowed">
                      <img class="label-picto" src="/images/smoking.png"/>
                    </div>
                  </div>
                </div>

                <div class="seats-selection">
                  <h2>{{ $t("Select your seat(s)") }}</h2>
                  <div class="fare-info">
                    <p class="fare-info__seats">{{ ride_infos[0].seats_available }} {{ $t(ride_infos[0].seats_available > 1 ? 'Seats Left' : 'Seat Left') }} -</p>
                    <p class="fare-info__fare">${{ seatFee }} {{ $t("per seat")}}</p>
                  </div>
                  <p>{{ $t("Click the seat(s) you want to select") }}</p>
                  <div v-if="seatConfigType==='sedan'" class="seats-config">
                    <img class="seats-config__sedan" src="@/assets/images/cartop.png"/>
                    <img class="seats-config--seat seats-config__sedan__seat-1" src="@/assets/images/top_seat_red.png"/>
                    <div class="seats-config--seat seats-config__sedan__seat-1__user" :style="'background-image: url(https://spawnaride.com/users-pictures/' + ride_infos[0].url + ');'"></div>
                    <img @click="configSeat(2)" class="seats-config--seat seats-config__sedan__seat-2" src="@/assets/images/top_seat.png"/>
                    <div v-if="bookRequestSeat[2]" @click="configSeat(2)" class="seats-config--seat seats-config__sedan__seat-2__user" :style="'background-image: url(' + this.user.profilePicture + ');'"></div>
                    
                    <img @click="configSeat(4)" class="seats-config--seat seats-config__sedan__seat-4" src="@/assets/images/top_seat_red.png"/>
                    <div v-if="bookRequestSeat[4]" @click="configSeat(4)" class="seats-config--seat seats-config__sedan__seat-4__user" :style="'background-image: url(' + this.user.profilePicture + ');'"></div>
                    
                    <img @click="configSeat(3)" class="seats-config--seat seats-config__sedan__seat-3" src="@/assets/images/top_seat.png"/>
                    <div v-if="bookRequestSeat[3]" @click="configSeat(3)" class="seats-config--seat seats-config__sedan__seat-3__user" :style="'background-image: url(' + this.user.profilePicture + ');'"></div>
                    
                    <img @click="configSeat(5)" class="seats-config--seat seats-config__sedan__seat-5" src="@/assets/images/top_seat.png"/>
                    <div v-if="bookRequestSeat[5]" @click="configSeat(5)" class="seats-config--seat seats-config__sedan__seat-5__user" :style="'background-image: url(' + this.user.profilePicture + ');'"></div>

                  </div>
                  <div v-else-if="seatConfigType==='van'" class="seats-config">
                    <img class="seats-config__van" src="@/assets/images/caravan.png"/>
                    <img class="seats-config__van__seat-1" src="@/assets/images/top_seat_red.png"/>
                    <div class="seats-config__van__seat-1__user" :style="'background-image: url(https://spawnaride.com/users-pictures/' + ride_infos[0].url + ');'"></div>
                    <img @click="configSeat(2)" class="seats-config__van__seat-2" src="@/assets/images/top_seat.png"/>
                    <div v-if="bookRequestSeat[2]" @click="configSeat(2)" class="seats-config--seat seats-config__van__seat-2__user" :style="'background-image: url(' + this.user.profilePicture + ');'"></div>
                    
                    <img @click="configSeat(4)" class="seats-config__van__seat-4" src="@/assets/images/top_seat.png"/>
                    <div v-if="bookRequestSeat[4]" @click="configSeat(4)" class="seats-config--seat seats-config__van__seat-4__user" :style="'background-image: url(' + this.user.profilePicture + ');'"></div>
                    
                    <img @click="configSeat(3)" class="seats-config__van__seat-3" src="@/assets/images/top_seat.png"/>
                    <div v-if="bookRequestSeat[3]" @click="configSeat(3)" class="seats-config--seat seats-config__van__seat-3__user" :style="'background-image: url(' + this.user.profilePicture + ');'"></div>
                    
                    <img @click="configSeat(5)" class="seats-config__van__seat-5" src="@/assets/images/top_seat.png"/>
                    <div v-if="bookRequestSeat[5]" @click="configSeat(5)" class="seats-config--seat seats-config__van__seat-5__user" :style="'background-image: url(' + this.user.profilePicture + ');'"></div>
                    
                    <img @click="configSeat(7)" class="seats-config__van__seat-7" src="@/assets/images/top_seat.png"/>
                    <div v-if="bookRequestSeat[7]" @click="configSeat(7)" class="seats-config--seat seats-config__van__seat-7__user" :style="'background-image: url(' + this.user.profilePicture + ');'"></div>
                    
                    <img @click="configSeat(6)" class="seats-config__van__seat-6" src="@/assets/images/top_seat.png"/>
                    <div v-if="bookRequestSeat[6]" @click="configSeat(6)" class="seats-config--seat seats-config__van__seat-6__user" :style="'background-image: url(' + this.user.profilePicture + ');'"></div>
                    
                    <img @click="configSeat(8)" class="seats-config__van__seat-8" src="@/assets/images/top_seat.png"/>
                    <div v-if="bookRequestSeat[8]" @click="configSeat(8)" class="seats-config--seat seats-config__van__seat-8__user" :style="'background-image: url(' + this.user.profilePicture + ');'"></div>
                  
                  </div>
                  <div v-else-if="seatConfigType==='coupe'" class="seats-config">
                    <img class="seats-config__convertible" src="@/assets/images/convertible.png"/>
                    <img class="seats-config__convertible__seat-1" src="@/assets/images/top_seat_red.png"/>
                    <div class="seats-config__convertible__seat-1__user" :style="'background-image: url(https://spawnaride.com/users-pictures/' + ride_infos[0].url + ');'"></div>
                    <img @click="configSeat(2)" class="seats-config__convertible__seat-2" src="@/assets/images/top_seat.png"/>
                    <div v-if="bookRequestSeat[2]" @click="configSeat(2)" class="seats-config--seat seats-config__convertible__seat-2__user" :style="'background-image: url(' + this.user.profilePicture + ');'"></div>

                  </div>
                </div>
                 

                  <h2>{{ $t("Message to") }} {{ ride_infos[0]?.name }}</h2>
                  <h2>{{ $t("Payment method") }}</h2>
                  
                  <form @submit.prevent="processPayment" class="payment-form" ref="process-form">
      <div class="form-row">
        <div class="form-group form-group--card-number">
          <label for="card-number">{{ $t('Card Number') }}:</label>
          <input
            type="text"
            id="card-number"
            v-model="cardNumber"
            maxlength="16"
            required
            placeholder="1234 5678 9012 3456"
          />
        </div>
        <div class="form-group form-group--expiry-month">
          <label for="expiry-month">{{ $t('Expiry Month') }}:</label>
          <input
            type="text"
            id="expiry-month"
            v-model="expiryMonth"
            maxlength="2"
            required
            placeholder="MM"
          />
        </div>
        <div class="form-group form-group--expiry-year">
          <label for="expiry-year">{{ $t('Expiry Year' )}}:</label>
          <input
            type="text"
            id="expiry-year"
            v-model="expiryYear"
            maxlength="2"
            required
            placeholder="YY"
          />
        </div>
        <div class="form-group form-group--expiry-cvv">
          <label for="cvv"> {{ $t('CVV') }}:</label>
          <input
            type="text"
            id="cvv"
            v-model="cvv"
            maxlength="3"
            required
            placeholder="123"
          />
        </div>
      </div>
      <div class="form-group ">
          <label for="promo-code">{{ $t('Promo code')}}:</label>
          <input
            type="text"
            id="promo-code"
            v-model="promoCode"
            maxlength="3"
            placeholder="123"
          />
        </div>
    </form>
    <p v-if="response">{{ response }}</p>
                  

                  <h2>{{ $t("Policies") }}</h2>
                  <h3>{{ $t("Payment policy") }}</h3>
                  <p>
                    {{ $t("Your card isn’t charged until the driver approves your Booking Request.") }}
                  </p>
                  <h3>{{ $t("Cancellation policy") }}</h3>
                  <p>
                    {{ $t("Full refund if your Booking Request isn't approved by the driver, is withdrawn or expires before being approved.") }}
                  </p>
                  <p>
                    {{ $t("100% refund if you cancel your booking more than 24h before departure") }}*
                  </p>
                  <p>
                    {{ $t("50% refund if you cancel your booking less than 24h before departure") }}*
                  </p>
                  <p>
                    *{{ $t("Applies only if your booking is approved, and the booking fee is non-refundable.") }}
                  </p>
              </div>
             <div class="right-side-page">
              <div class="booking-request">
                <h2 class="booking-request__title">{{ $t('Booking request') }}</h2>
                  <p class="booking-request__info">{{ initial_segment[0]?.il_city || '' }} {{ $t('to') }} {{ final_segment[0]?.fl_city || '' }}</p>   
                  <p class="booking-request__info" v-if="initial_segment[0].il_id !== final_segment[0].il_id">
                    {{ $t('Estimated Departure') }}: {{ calculateStopDepartureTime(ride_infos[0].departure_datetime, calculateDurationToStart(ride_infos[0].fareData,initial_segment[0].il_city, final_segment[0].fl_city)) }}<br>
                    <span>{{  $t("Please reach out to the driver to confirm the exact departure time from this stop.") }}</span>
                  </p>
                  <p v-else class="booking-request__info">
                    {{ calculateStopDepartureTime(ride_infos[0].departure_datetime, 0) }}
                  </p>
                  <hr>
                  <table class="pricing-table">
                    <thead>
                      <tr>  
                        <th>{{ $t('Item') }}</th>
                        <th>{{ $t('Amount') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ bookedSeat }} {{ $t(bookedSeat>1 ? 'seats' : 'seat') }}</td>
                        <td>${{ Number(bookedSeat*seatFee).toFixed(2) }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('Booking fee') }}</td>
                        <td>${{ Number(bookedSeat*seatFee*.15).toFixed(2) }}</td>
                      </tr>
                      <tr class="total-row">
                        <td>{{$t('Total') }}</td>
                        <td>${{ Number(bookedSeat*seatFee*1.15).toFixed(2) }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <button @click="submitForm()" class="btn btn--primary">{{ $t('Request to book') }}</button>
                </div>
              </div>  
              <div class="bottom-page">

                <div class="bottom-page__left">
                 
                </div>
                <div class="bottom-page__right">
                 

                </div>
              </div>            
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Cookies from 'js-cookie';
  import { LMap, LTileLayer, LMarker, LPolyline } from "@vue-leaflet/vue-leaflet";
  // import { nextTick } from 'vue';
  export default {
    name: 'BookRide',
    props: {
      uri: String,
      user: []
    },
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LPolyline,
    },
    async mounted () {
      this.ride_id = this.$route.params.ride_id;
      this.initial_segment_id = this.$route.query.is;
      this.segment_id = this.$route.query.s;
      if(this.isLoged()) {
        this.$router.push({ query: { ...this.$route.query, menuType: 'app' } });
      }      
      else {
        this.$router.push('/login?next=/book-ride/request/' + this.ride_id +'/?is=' + this.initial_segment_id + '&s=' + this.segment_id);
      }

      this.getRideInfos();
    },
    data() {
      return {
        cardNumber: '4111111111111111',
        expiryMonth: '12',
        expiryYear: '30',
        cvv: '123',
        promoCode: '',
        response: null,
        seatFee: null,
        bookedSeat: 1,
        next: false,
        zoom: 6,
        startLocationCoords: null,
        endLocationCoords: null,
        center: [45.5019535, -73.5710061], // Montreal
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        stopCoords: [],
        routeCoords: [],
        ride_infos: [] || {},
        initial_segment: [] || {},
        final_segment: [] || {},
        token: Cookies.get('authToken'),
        name: '',
        pickup: '',
        destination: '',
        pickupTime: '',
        ride_id: null, // Receives the id parameter from the route
        initial_segment_id: null,
        segment_id: null,
        loaded: false,
        seatConfigType: null,
        bookRequestSeat: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          7: false,
          8: false,
        }
      };
    },
    methods: {
      configSeat (index) {
        
        this.bookRequestSeat[index] = !this.bookRequestSeat[index];
        let countBooked = 0;
        for (let i = 0; i < 8; i++) {
          countBooked += this.bookRequestSeat[i] ? 1 : 0;
        }
        this.bookedSeat = countBooked > 0 ? countBooked : 1;
      },
      isLoged () {
        return this.token;
      },
      formatAddress(prefix, location) {
        const houseNumber = location[`${prefix}_house_number`];
        const street = location[`${prefix}_street`];
        const city = location[`${prefix}_city`];
        const postalCode = location[`${prefix}_postalcode`];
        const province = location[`${prefix}_province`];
        const country = location[`${prefix}_country`];

        // Combine house number and street name if both exist
        const addressLine1 = houseNumber && street ? `${houseNumber} ${street}` : street || houseNumber || "";

        // Assemble other address parts dynamically
        const addressParts = [addressLine1, city, province, postalCode, country].filter(Boolean);

        // Join the parts into a nicely formatted address
        return addressParts.join(", ");
      },
      async getRoute () {
      let osrmUrl = '';
      osrmUrl = `https://router.project-osrm.org/route/v1/driving/${this.startLocationCoords[1]},${this.startLocationCoords[0]};${this.endLocationCoords[1]},${this.endLocationCoords[0]}?overview=full&geometries=geojson`;
      const response = await axios.get(osrmUrl);

      const route = response.data.routes[0];
        this.distance=route.distance / 1000;
        this.duration=route.duration / 60;

        this.routeCoords = route.geometry.coordinates.map(coord => [coord[1], coord[0]]); // Swap [lon, lat] to [lat, lon]

    
    },
      matchedFare (result, fromPlace, toPlace) {
        if (typeof result === 'string') {
          result = JSON.parse(result);
        }
        const matchedObject = result.find(route => route.from === fromPlace && route.to === toPlace);
        return matchedObject;
      },
      openDatepicker() {
        const dateCalendar = document.getElementById('dateFrom');
        dateCalendar.click();
      },
      calculateDurationToStart(routes, startCity) {
        routes = JSON.parse((routes));
          if (!Array.isArray(routes)) {
              console.error("Expected 'routes' to be an array, but got:", routes);
              throw new TypeError("Invalid input: 'routes' must be an array.");
          }
          let totalDuration = 0;
          let currentCity = routes[0].from; // First city is always routes[0].from

          while (currentCity !== startCity) {

              const nextRoute = routes.find(route => route.from === currentCity);
              if (!nextRoute) {
                  throw new Error(`No route found from ${currentCity} to reach ${startCity}`);
              }

              totalDuration += nextRoute.duration;
              currentCity = nextRoute.to;

              if (currentCity === startCity) break;

              if (!routes.some(route => route.from === currentCity)) {
                  throw new Error(`No further routes available from ${currentCity} to reach ${startCity}`);
              }
          }

          return totalDuration;
      },
      calculateTotalDuration(routes, startCity, endCity) {
        let totalDuration = 0;
        let currentCity = startCity;
        routes = JSON.parse((routes));
        while (currentCity !== endCity) {
            const nextRoute = routes.find(route => route.from === currentCity);
            if (!nextRoute) {
                throw new Error(`No route found from ${currentCity} to ${endCity}`);
            }

            totalDuration += nextRoute.duration;
            currentCity = nextRoute.to;

            if (currentCity === endCity) break;


            if (!routes.some(route => route.from === currentCity)) {
                  throw new Error(`No further routes available from ${currentCity} to reach ${endCity}`);
              }
          }

          return totalDuration;
      },
      formatTimeFromSeconds(seconds) {
          const hours = Math.floor(seconds / 3600);
          const minutes = Math.floor((seconds % 3600) / 60);
          
          return `${hours} hour${hours !== 1 ? 's' : ''}, ${minutes} minute${minutes !== 1 ? 's' : ''}`;
      },
      calculateStopDepartureTime(departure_datetime, durationInSeconds) {

        durationInSeconds = durationInSeconds || 0;
        // Convert the departure datetime string to a Date object
        let departureDate = new Date(departure_datetime);
        
        // Add the duration (in seconds) to the departure time
        departureDate.setSeconds(departureDate.getSeconds() + durationInSeconds);
        
        // Format the result as a human-readable string
        let stopDepartureTime = departureDate.toLocaleString("en-US", {
          weekday: 'short', // "Mon", "Tue", etc.
          year: 'numeric',
          month: 'short', // "Nov"
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric', // Include seconds
          hour12: true // "AM/PM"
        });
        
        return stopDepartureTime;
      },
      submitForm() {
        const form = this.$refs['process-form'];
        if (form.checkValidity()) {
          this.processPayment()
        } else {
          form.reportValidity();
        }
      },
      async processPayment() {
        try {


          const paymentData =  {
            cardNumber: this.cardNumber,
            expiryMonth: this.expiryMonth,
            expiryYear: this.expiryYear,
            cvv: this.cvv
          }

          const res = await axios.post(`${this.uri}/api/payment`, paymentData, {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          });

          this.response = res.data.message;
        } catch (error) {
          this.response = error.response?.data?.message || 'Payment failed';
        }
      },
      async getRideInfos () {
        let resp = await axios({
          method: 'get',
          url: this.uri + '/api/rides/details?ride_id=' + this.ride_id,
          headers: { 
            'Content-Type': 'application/json'
          }
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
        this.ride_infos = resp;

        resp = await axios({
          method: 'get',
          url: this.uri + '/api/rides/segment?ride_segment_id=' + this.initial_segment_id,
          headers: { 
            'Content-Type': 'application/json'
          }
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
        this.initial_segment = resp;

        resp = await axios({
          method: 'get',
          url: this.uri + '/api/rides/segment?ride_segment_id=' + this.segment_id,
          headers: { 
            'Content-Type': 'application/json'
          }
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
        this.final_segment = resp;
        this.startLocationCoords = [this.initial_segment[0].il_latitude, this.initial_segment[0].il_longitude];
        this.endLocationCoords = [this.final_segment[0].fl_latitude,this.final_segment[0].fl_longitude];
        this.center = [
                        (parseInt(this.initial_segment[0].il_latitude) + parseInt(this.final_segment[0].fl_latitude))/2, 
                        (parseInt(this.initial_segment[0].il_longitude) + parseInt(this.final_segment[0].fl_longitude))/2
                      ];
        this.getRoute();
        this.seatFee = this.matchedFare(this.ride_infos[0].fareData, this.initial_segment[0].il_city, this.final_segment[0].fl_city)?.fare;
        this.seatConfigType = this.ride_infos[0].seat__congif_type;
        this.loaded = true;
        
      },
      submitBooking() {
        // Handle the booking submission
        const bookingDetails = {
          name: this.name,
          pickup: this.pickup,
          destination: this.destination,
          pickupTime: this.pickupTime,
          vehicleId: this.id,
        };
        // Here you would typically make an API call to save the booking
        console.log('Booking Details:', bookingDetails);
      },
    },
  };
  </script>
  
  <style scoped>
.payment-form-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.payment-form-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.payment-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-row {
}

.form-group {
  margin: 15px 10px;
}
.form-group--card-number {
  width: 190px;
  float: left;
}
.form-group--expiry-month, .form-group--expiry-year, .form-group--expiry-cvv {
  width: calc((100% - 270px)/3);
  float: left;
}
.form-group label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #555;
}

.form-group input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
}

.form-group input:focus {
  border-color: #007bff;
}

.submit-btn {
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.response-message {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #007bff;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
}
  .pricing-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    text-align: left;
    border: 1px solid #ddd;
  }

  .pricing-table th {
    background-color: #f8f8f8;
    font-weight: bold;
    font-size: 16px;
    padding: 10px;
    border-bottom: 2px solid #ddd;
  }

  .pricing-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  .pricing-table td:nth-child(2),
  .pricing-table th:nth-child(2) {
    text-align: right;
  }

  .pricing-table .total-row {
    font-weight: bold;
    background-color: #f8f8f8;
  }
  h1 { 
    margin: 10px 0 0!important;
    font-size: 26px;
  }
  h2 {
    padding: 0;
    margin: 10px 0 5px;
    font-size: 22px;
    font-weight: 600;
  }
  h3 {
    margin: 0;
    font-size: 16px;
  }
  .bottom-page {
    width: 100%;  
  }
  .bottom-page__left {
    width: calc(60%);
    float:left;
  }
  .bottom-page__right {
    width: calc(40%);
    float:left;
  }
  .book-ride {
    max-width: 1200px!important;
    margin: auto;
    padding: 70px 20px 20px 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    display: grid;
  }
  .datepicker-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-group {
  display: flex;
  align-items: center;
  position: relative;
}

.form-control {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.icon-wrapper {
  cursor: pointer;
  color: #8ace00;
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0,-50%);
} 
.flatpickr-day.startRange,  
.flatpickr-day.endRange {
  background: #FF5722 !important; /* Orange for start/end of range */
  color: #fff !important;
}
.flatpickr-day.today {
  background: #8ace00 !important; /* Blue background for today */
  color: #fff !important;
}
.flatpickr-day.today.selected {
    background: #8ace00;
}
.icon-wrapper:hover {
  color: #75af00;
  font-size: 30px;
}

.fas.fa-calendar-alt {
  font-size: 16px;
}


  .loaging-page__loader {
    width: 46px;
  }
  .results {
  margin-top: 20px;
  padding: 10px;
}
.search-result {

}
.results-grid {

}
.car-info__picto {
  border: 1px solid #8ace00;
  display: block;
  float: left;
  padding: 3px;
  border-radius: 10px;
  margin: 0 3px 0;
  background: #f5f5f5;
  height: 45px;
}
.result-card {
  color:initial;

  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s;
  width: 100%;
  display: flex;
  margin: 0 0 15px;
}
.result-card:hover {
  color:initial;
}
/* .result-card:hover {
  transform: scale(1.02);
} */
.left-side-page{
  width: calc(60%);
  display: inline-block;
  float: left;
}
.right-side-page{
  max-width: 480px;
  width: 40%;
  display: inline-block;
  float: left;
  padding: 20px;
}
.booking-request {
  border: 1px solid hsl(0, 0%, 75%);
  border-radius: 20px;
  padding: 20px;

}
.seats-config {
  max-width: 100%;
  width: 200px;
  float: left;
  position: relative;
  padding: 0;
  margin: 20px 0 0;
}
.seats-config__sedan {
  width: 100%;
  position: relative;
  z-index: 0;
  opacity: .7;

}

.seats-config__sedan__seat-1 {
  width: calc(100%/3.5);
  position: absolute;
  top: 30%;
  left: 12%;
  opacity: .9;
}
.seats-config__sedan__seat-1__user {
  top: 38%;
  left: calc(14%);
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
}
.seats-config__sedan__seat-2 {
  width: calc(100%/3.5);
  position: absolute;
  top: 30%;
  right: 12%;
  opacity: .9;
}

.seats-config__sedan__seat-2__user {
  top: 38%;
  right: calc(14%);
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
}
.seats-config__sedan__seat-3 {
  width: calc(100%/3.5);
  position: absolute;
  top: 60%;
  left: 12%;
  opacity: .9;
}

.seats-config__sedan__seat-3__user {
  top: 68%;
  left: calc(14%);
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
  z-index: 20;
}
.seats-config__sedan__seat-4 {
  width: calc(100%/3.5);
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: .9;
  
}

.seats-config__sedan__seat-4__user {
  top: 68%;
  left: calc(50%);
  transform: translate(-50%, 0);
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
  z-index: 19;

}
.seats-config__sedan__seat-5 {
  width: calc(100%/3.5);
  position: absolute;
  top: 60%;
  right: 12%;
  opacity: .9;
}

.seats-config__sedan__seat-5__user {
  top: 68%;
  right: calc(14%);
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
  z-index: 20;
}
.seats-config__van {
  width: 100%;
  position: relative;
  z-index: 0;
  opacity: .7;
}
.seats-config__van__seat-1 {
  width: calc(100%/4);
  position: absolute;
  top: 27%;
  left: 15%;
  opacity: .9;
  z-index: 8;
}
.seats-config__van__seat-1__user {
  top: 35%;
  left: calc(16%);
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
  z-index: 9;
}
.seats-config__van__seat-2 {
  width: calc(100%/4);
  position: absolute;
  top: 27%;
  right: 15%;
  opacity: .9;
  z-index: 8;
}
.seats-config__van__seat-2__user {
  top: 35%;
  right: calc(16%);
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
  z-index: 9;
}
.seats-config__van__seat-3 {
  width: calc(100%/4);
  position: absolute;
  top: 45%;
  left: 15%;
  opacity: .9;
}
.seats-config__van__seat-3__user {
  top: 50%;
  left: calc(16%);
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
  z-index: 9;
}
.seats-config__van__seat-4 {
  width: calc(100%/4);
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: .9;
  
}
.seats-config__van__seat-4__user {
  top: 50%;
  left: calc(50%);
  transform: translate(-50%, 0);
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
  z-index: 9;
}
.seats-config__van__seat-5 {
  width: calc(100%/4);
  position: absolute;
  top: 45%;
  right: 15%;
  opacity: .9;

}
.seats-config__van__seat-5__user {
  top: 50%;
  right: calc(16%);
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
  z-index: 9;
}
.seats-config__van__seat-6 {
  width: calc(100%/4);
  position: absolute;
  top: 65%;
  left: 15%;
  opacity: .9;
}
.seats-config__van__seat-6__user {
  top: 70%;
  left: calc(16%);
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
  z-index: 9;
}
.seats-config__van__seat-7 {
  width: calc(100%/4);
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: .9;
  
}
.seats-config__van__seat-7__user {
  top: 70%;
  left: calc(50%);
  transform: translate(-50%, 0);
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
  z-index: 9;
}
.seats-config__van__seat-8 {
  width: calc(100%/4);
  position: absolute;
  top: 65%;
  right: 15%;
  opacity: .9;

}
.seats-config__van__seat-8__user {
  top: 70%;
  right: calc(16%);
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
  z-index: 9;
}
.seats-config__convertible {
  width: 100%;
  position: relative;
  z-index: 0;
  opacity: .7;
}
.seats-config__convertible__seat-1 {
  width: calc(100%/3);
  position: absolute;
  top: 35%;
  left: 15%;
  opacity: .9;
  z-index: 8;
}
.seats-config__convertible__seat-1__user {
  top: 45%;
  left: calc(18%);
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
  z-index: 9;
}
.seats-config__convertible__seat-2 {
  width: calc(100%/3);
  position: absolute;
  top: 35%;
  right: 15%;
  opacity: .9;
  z-index: 8;
}
.seats-config__convertible__seat-2__user {
  top: 45%;
  right: calc(18%);
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
  border:3px solid #8ACE00;
  z-index: 9;
}
.seats-config--seat {
  transition: all .5s ease;
  opacity: 1;
  cursor: pointer;
}
.seats-config--seat:hover {
  opacity: 1;
}

.car-info {
    padding: 10px;
    width: 100%;
    float: left;
    position: relative;
}
.seats-selection {
  width: 100%;
  float: left;
}
.fare-info {
  padding: 10px;
  width: 100%;
  float: left;
  position: relative;
  text-align: right;
}
.fare-info__fare {
  color: #8ace00;
  font-size: 26px;
  font-weight: 700;
  float: left;
  margin: -7px 0 0 5px;
}
.fare-info__seats {
  font-weight: 700;
  float: left;
  
}
.car-picture {
  border-radius: 20px;
  width: 140px;
  height: 100px;
  background-size: cover;
  background-position: center center;
  float: left;
}
.car-info__infos {
  width: calc(100$ - 150px);
  margin: 0 0 0 10px;
  float: left;
  
}
.driver-info {
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  width: 100%;
  float: left;
  position: relative;
}
.driver-info:after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 1px;
    height: 80%;
    background: #bebebe;
    content: none;
}
.driver-picture {
  width: 90px;
  height: 90px;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  margin-right: 15px;
  position: relative;
  float: left;

}

.driver-details {
  float: left;
  display: block;
}
.driver-details h3 {
  margin: 10px 0 0;
  font-size: 23px;
  text-align: left;
  font-weight: 700;
}
.rating {
  text-align: center;
  padding: 0 0 0 40px;
  position: relative;
}
.rating span {
  color: rgb(230, 214, 0);
  font-size: 32px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0,-50%);
}
.driven {
  margin: 0;
  padding: 0;
  text-align: center;
}
.ride-details {
  padding: 20px;
  width: calc(100%);
  float: left;
  position: relative;
}
.ride-details__title {
  color: #111;
  font-weight: 700;
  font-size: 21px!important;
}
.departure-time {
  font-weight: 700;
}
.departure-time__warning span {
  color: rgb(255, 102, 0);
}
.ride-details:after {
  position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: 80%;
    background: #bebebe;
    content: none;
}
.car-info:after {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(0, -50%);
    width: 1px;
    height: 100%;
    background: #bebebe;
    content: none;
}
.ride-details p {
  margin: 5px 0;
  font-size: 14px;
}
.ride-details span {
  color: #111;
}
  .title {
    text-align: center;
    color: #333;
  }
  .result-title {
    margin: 0 0 20px;
    padding: 0;
  }
  .search-form {
    width: 100%;
    display: inline-block;
    background: rgb(228, 228, 228);
    border-radius: 10px;
    margin: 0;
    padding: 0px;
    height: auto;
    min-height: 0;
  }
  
  .form-group {
  }
  .form-group__from {
    width: calc((100% - 400px) / 2);
    float: left;
    display: inline-block;
    margin: 0 10px 0 10px;

  }
  .form-group__from__input-container {
    width: 100%;
    position: relative;
    padding: 0;
    margin: 10px 0 10px 0;
  }
  .form-group__from__input {
    position: relative;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
  }
  .form-group__from__input__close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    padding: 20px 15px;
    font-size: 20px;
  }
  .form-group__switch {
    width: 60px;
    float: left;
    display: inline-block;
    margin: 15px 10px 0 20px;
    border-radius: 10px;
    padding: 10px 20px 0px 10px;
    margin: 15px 0;
  }
  .form-group__to {
    width: calc((100% - 400px) / 2);
    float: left;
    display: inline-block;
    margin: 0 10px 0 0;
  }
  .form-group__when {
    width: 140px;
    float: left;
    display: inline-block;
    margin: 0 20px 0 0;
    height: auto;
    position: relative;
  }
  .form-group__when input {
    padding: 20px 10px;
    font-size: 16px;
    width: 140px;
    margin: 10px 0;
  }
  .form-group__button {
    width: 140px;
    float: right;
    margin: 13px 10px 0 0;
    height: auto;
    padding: 1px 0!important;
    display: inline-block;
    border-radius: 10px!important;
  }
  .input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
  }
  ::placeholder {
      color: #000000;
      opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder { /* Edge 12 -18 */
      color: #000000;
    }
  
  .suggestions {
    list-style-type: none;
    padding: 0;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    position: absolute
  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  
  .button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #8ACE00;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: #111;
  }
  
  .button:hover {
    background-color: #8ACE00;
  }
  
  .results {
    margin-top: 20px;
  }
  
  .results-list {
    list-style-type: none;
    padding: 0;
  }
  
  .result-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .result-item:last-child {
    border-bottom: none;
  }
  .right-side-page {
      position: fixed
    }
  @media only screen and (max-width:800px) {
    h1 {
      font-size: 18px;
    }
    .driver-picture {
      width: 75px;
      height: 75px;
      margin-top: 7px;
    }
    .car-picture {
      height: 150px;
    }
    .seats-config {
        left: 50%;
        transform: translate(-50%, 0);
    }
    .leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom {
      height: 230px !important;
    }
    .bottom-page__left {
      width: 100%;
    }
    .bottom-page__right {
      width: 100%;
    }
    .left-side-page {
      width: 100%;
    }
    .right-side-page {
      width: 100%;
      position: relative
    }
    .search-form {
      position: relative;
      padding: 10px 0;
    }
    .form-group {
      width: calc(100% - 20px)!important;
      margin: 0 10px;
    }
    .form-group__from__input-container {
      margin: 0;
    }
    .form-group__switch {
      position: absolute;
      right: 90px;
      background: rgb(204 204 204);
      padding: 2px;
      top: 36px;
      z-index: 10;
      padding: 4px;
      transform: rotate(90deg);
      width: 42px;
      height: 42px;
    }
    .form-group__switch img {
      margin: 0;
      padding: 0;
      background-color: #444444;
      border-radius: 7px;
      padding: 5px;
      width: 100px;
    }
    .form-group__when {
      width: calc(100% - 20px)!important;
      margin: 0 10px;
    }
    #dateFrom {
      margin: 0;
      width: 100%;
    }
    .fare-info {
      margin: 0;
      width: 100%;
    }
    .button.form-group__button {
      width: calc(100% - 20px);
    }
    .driver-info {
      width: 100%;
    }
    .ride-details {
      width: 100%;
    }
    .car-info {
      width: 100%;
    }
    .result-card {
      display: block;
    }
    .form-group__when {
      
    }
    /* .icon-wrapper {
      display: none;
    } */
     
  }
  </style>
  