<template>
    <div class="app-page">
  
      <section class="contact">
        <h1>Nous Contacter</h1>
        <p>Vous avez des questions ou des commentaires ? N'hésitez pas à nous écrire.</p>
    
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="name">Nom :</label>
            <input type="text" id="name" v-model="form.name" required />
          </div>
          <div class="form-group">
            <label for="email">Email :</label>
            <input type="email" id="email" v-model="form.email" required />
          </div>
          <div class="form-group">
            <label for="message">Message :</label>
            <textarea id="message" v-model="form.message" required></textarea>
          </div>
          <button class="btn btn--primary" type="submit">Envoyer</button>
        </form>
      </section>
      <div v-if="isLoading" class="loaging-page"><img class="loaging-page__loader" src="@/assets/images/loader.gif"/></div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useNotification } from "@kyvg/vue3-notification";
  const { notify } = useNotification()
  export default {
    name: 'ContactComponent',
    mounted () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    props: {
      uri: String
    },
    data() {
      return {
        isLoading: false,
        form: {
          name: '',
          email: '',
          message: '',
        },
      };
    },
    methods: {
      async sendMessage() {
        this.isLoading = true;
        if (!this.form.name || !this.form.email || !this.form.message) {
          notify({
            title: "Message",
            text: "Please fill name, email and message.",
            type: 'warn',
          });
          this.isLoading = false;
          return false;
        }
        try {
          const response = await axios.post(this.uri + `/api/contact/`, 
          {
              name: this.form.name, email: this.form.email, message: this.form.message 
          });
          console.log(response)
          this.isLoading = false;
          return true;
        } catch (error) {
          this.isLoading = false;
          console.error('Error marking messages as seen:', error);
          return false;
        }
      },
      async submitForm() {
        // Handle form submission (e.g., send to API)
        console.log('Form submitted:', this.form);
        if(await this.sendMessage()) {
          notify({
            title: "Message",
            text: "Votre message a été envoyé avec succès !",
          });
          // Reset form
          this.form.name = '';
          this.form.email = '';
          this.form.message = '';
        } else {
          notify({
            title: "Erreur",
            text: "Votre message n'a pasété envoyé.",
            type: 'warn',
          });
        }

        }
      }
  };
  </script>

<style scoped>
.loaging-page {
      background-color: rgba(0,0,0,.7);
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10000;
    }
    .loaging-page__loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
    }
  .contact {
    max-width: 600px;
      margin: 0 auto;
      padding: 20px;
      background-color: #f9f9f9;
      border-radius: 8px;
  }
  input, textarea, button {
    width: 100%;
  }
</style>