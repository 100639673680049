<template>
    <div class="app-page">
      <div class="login-container">
        <h1>{{ $t("Login") }}</h1>
        <form @submit.prevent="login">
          <div class="form-group">
            <label for="email">{{ $t("Email") }}</label>
            <input v-model="email" type="email" id="email" :placeholder="$t('Enter your email')" required />
          </div>
          <div class="form-group">
            <label for="password">{{ $t("Password")}}</label>
            <input v-model="password" type="password" id="password" :placeholder="$t('Enter your password')" required />
          </div>
          <button class="btn btn--primary" type="submit">{{  $t("Login") }}</button>
          <router-link :to="getLocaleLink(this.next ? '/register?next=' + this.next : '/register')" class="black-text">{{ $t("Not registered yet? Click here to register.") }}</router-link>

        </form>
        <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
      </div>

    </div>
  </template>
  
  <script>
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
  props: {
    uri: String,
    loadUser: Function,
    getLocaleLink: Function
  },
  mounted () {
    if(this.isLoged()) {
      this.$router.push('/dashboard');
    }      
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    this.next = this.$route.query.next ? this.$route.query.next + '&s=' + this.$route.query.s : false;
  },
  data() {
    return {
      next: '',
      email: '',
      password: '',
      errorMessage: '',
      resp: [],
      token: Cookies.get('authToken'),
    };
  },
  methods: {

    isLoged () {
      return this.token;
    },
    async login() {
      try {
        const response = await axios.post(
          this.uri + '/api/auth/login',
          {
            email: this.email,
            password: this.password,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        if(response?.data?.token) {
          const token = response.data.token;
          Cookies.set('authToken', token, { expires: 7 });
          await this.loadUser();
          if(this.next) {
            this.$router.push(this.getLocaleLink(this.next));
          } else {
            this.$router.push(this.getLocaleLink('/dashboard'));
          }
        }
        else {
          this.errorMessage = 'Invalid email or password';
        }
      } catch (error) {
        this.errorMessage = 'Invalid email or password';
        console.error(error);
      }
    },
  }
};
  </script>
  
  <style scoped>
  .error-message{
    color: red;
  }
  .login-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    min-height: 100vh;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
  }
  button {
    width: 100%;
  }
  </style>
  