<template>
  <div class="app-page">
    <div class="notifications">
      <header>
        <h1>Paramètres de Notifications</h1>
      </header>
      <div class="container">
        <form @submit.prevent="updateNotifications">
          <div class="form-group">
            <label for="email-notifications">
              <input
                type="checkbox"
                id="email-notifications"
                v-model="notifications.email"
              />
              Recevoir des notifications par Email
            </label>
          </div>

          <div class="form-group">
            <label for="sms-notifications">
              <input
                type="checkbox"
                id="sms-notifications"
                v-model="notifications.sms"
              />
              Recevoir des notifications par SMS
            </label>
          </div>

          <div class="form-group">
            <button class="btn btn--primary" type="submit">Sauvegarder</button>
          </div>
        </form>
        <div v-if="message" class="message">{{ message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    uri: String
  },
  data() {
    return {
      notifications: {
        email: false,
        sms: false,
      },
      message: ''
    };
  },
  methods: {
    async loadNotifications() {
      // Remplacer par l'URL de votre API pour récupérer les paramètres de notification
      const response = await fetch(this.uri + '/api/users/notifications');
      this.notifications = await response.json();
    },
    async updateNotifications() {
      try {
        // Remplacer par l'URL de votre API pour mettre à jour les paramètres de notification
        const response = await fetch(this.uri + '/api/users/notifications', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.notifications)
        });

        if (!response.ok) {
          throw new Error('Échec de la mise à jour des paramètres de notification');
        }

        this.message = 'Paramètres de notification mis à jour avec succès!';
      } catch (error) {
        this.message = error.message;
      }
    }
  },
  created() {
    this.loadNotifications();
  },
    watch: {
      $route() {
      // Push a new query parameter when the route changes
      this.$router.push({ query: { menuType: 'app' } });
    },
    },
    mounted () {
      this.$router.push({ query: { menuType: 'app' } });
    }
};
</script>

<style scoped>
.notifications {
  max-width: 600px;
    margin: 30px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

header {
  background-color: #8ACE00;
  color: white;
  padding: 20px;
  text-align: center;
}

.container {
  margin: 20px auto;
  max-width: 600px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

button {
  width: 100%;
}

.message {
  margin-top: 20px;
  color: 8ACE00;
  text-align: center;
}
</style>
