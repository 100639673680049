<template>
    <div class="app-page">
        <div class="driver-evaluation">
            <h1>Évaluation du Conducteur</h1>
        
            <div v-if="submitted">
                <p>Merci pour votre évaluation !</p>
                <p>Total: {{ calculateTotal() }}/5</p>
            </div>
        
            <div v-else>
                <form @submit.prevent="submitEvaluation">
                <div v-for="(criteria, index) in evaluationCriteria" :key="index" class="criteria">
                    <p>{{ criteria.name }}</p>
                    <div class="stars">
                    <span
                        v-for="star in 5"
                        :key="star"
                        class="star"
                        :class="{ active: star <= criteria.rating }"
                        @click="setRating(index, star)">
                        ★
                    </span>
                    </div>
                </div>
        
                <div class="comment">
                    <label for="comment">Commentaire :</label>
                    <textarea 
                    id="comment" 
                    v-model="comment" 
                    placeholder="Écrivez votre commentaire ici..."></textarea>
                </div>
        
                <p>Total: {{ calculateTotal() }}/5</p>
        
                <button type="submit" class="submit-button">Soumettre</button>
                </form>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      uri: String
    },
    data() {
      return {
        evaluationCriteria: [
          { name: "Ponctualité", rating: 0 },
          { name: "Sécurité", rating: 0 },
          { name: "Confort", rating: 0 },
          { name: "Courtoisie", rating: 0 },
          { name: "Fiabilité", rating: 0 },
          { name: "Propreté", rating: 0 },
          { name: "Communication", rating: 0 },
          { name: "Conduite Éco-responsable", rating: 0 },
        ],
        comment: "",
        submitted: false,
      };
    },
    methods: {
      setRating(index, star) {
        this.evaluationCriteria[index].rating = star;
      },
      calculateTotal() {
        const total = this.evaluationCriteria.reduce((sum, criteria) => sum + criteria.rating, 0);
        return (total / this.evaluationCriteria.length).toFixed(1);
      },
      submitEvaluation() {
        if (this.evaluationCriteria.some((criteria) => criteria.rating === 0)) {
          alert("Veuillez évaluer tous les critères.");
          return;
        }
  
        const evaluationData = {
          criteria: this.evaluationCriteria,
          comment: this.comment,
          totalScore: this.calculateTotal(),
        };
  
        console.log("Données soumises :", evaluationData);
        this.submitted = true;
      },
    },
  };
  </script>
  
  <style scoped>
  .driver-evaluation {
    max-width: 600px;
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  .criteria {
    margin: 20px 0;
  }
  
  .stars {
    display: flex;
    gap: 5px;
    cursor: pointer;
  }
  
  .star {
    font-size: 24px;
    color: #ccc;
  }
  
  .star.active {
    color: gold;
  }
  
  .comment {
    margin: 20px 0;
  }
  
  textarea {
    width: 100%;
    min-height: 80px;
    resize: none;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  </style>
  