<template>

    <!-- preloader
    ================================================== -->
    <div id="preloader" style="display: none!important;">
        <div id="loader" class="dots-jump">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>


    <!-- header 
    ================================================== -->
    <header class="s-header">

        <div class="row">
            <div class="header-logo">
                <router-link class="site-logo" :to="getLocaleLink('')">
                    <img src="/images/Spawn_logo_512px_white.png" alt="Homepage">
                    <div>
                      SpawnARide
                    </div>
                </router-link>
            </div>
            <nav class="header-nav-wrap">
              <ul class="header-main-nav">
                  <li><router-link class="smoothscroll" :to="getLocaleLink('/#about')" title="about">{{ $t("About") }}</router-link></li>
                  <li><router-link class="smoothscroll" :to="getLocaleLink('/#features')" title="features">{{ $t("Features") }}</router-link></li>
                  <li><router-link class="smoothscroll" :to="getLocaleLink('/#pricing')" title="pricing">{{ $t("Pricing") }}</router-link></li>
                  <li><router-link class="smoothscroll" :to="getLocaleLink('/#download')" title="download">{{ $t("Download") }}</router-link></li>	
              </ul>
              <div class="header-cta">
                
                <select
                class="btn header-cta__btn header__select"
                  id="language-selector"
                  v-model="selectedLanguage"
                  @change="gotoPath(selectedLanguage)"
                >
                  <option
                    v-for="(label, key) in languages"
                    :key="key"
                    :value="key"
                    @click="gotoPath(key)"
                  >
                    {{ label }}
                  </option>
                </select>
                <router-link :to="getLocaleLink('/login')" class="btn btn--secondary header-cta__btn smoothscroll" style="margin-right: 15px; margin-bottom: 15px">{{ $t("Login") }}</router-link>
                <router-link :to="getLocaleLink('/register')" class="btn btn--primary header-cta__btn smoothscroll">{{ $t("Create an Account") }}</router-link>
              </div>
            </nav> <!-- end header-nav-wrap -->
    
            <a class="header-menu-toggle" href="#"><span>Menu</span></a>
        </div>

    </header> <!-- end header -->
  </template>
  
  <script>
  export default {
    name: 'HeaderView',
    props: {
      uri: String,
      getLocaleLink: Function
    },
    watch: {
      '$i18n.locale'(newLocale, oldLocale) {
        console.log(`Locale changed from ${oldLocale} to ${newLocale}`);
        this.selectedLanguage = newLocale;
      },
    },
    methods: {
      gotoPath(path) {
        this.$router.push('/' + path)
      },
      emitLanguageChange() {
        this.$emit('change-language', this.selectedLanguage);
      },
      changeLanguage(lang) {
        this.currentLanguage = lang;
      },
      togglePreInscription() {
        // Implement functionality to handle pre-registration
        alert('Préinscription functionality to be implemented.');
      },
    },
    data () {
      return {
        selectedLanguage: this.$i18n.locale,
        languages: {
          en: 'English',
          fr: 'Français',
          es: 'Español',
          de: 'Deutsch',
          it: 'Italiano',
          zh: '中国人',
        }
      }
    }
  };
  </script>
  
  <style scoped lang="scss">
  .header__select {
    padding: 0;
    margin: 0 20px 0 0;
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #111;
    padding: 20px;
    .header-cta {
      margin: 7px 0;
    }
    .logo {
      h1 {
        color: #8ACE00;
      }
    }
    
    nav {
      ul {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
  
        li {
          margin: 0 15px;
  
          a {
            color: #fff;
            text-decoration: none;
  
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    
    .pre-inscription {
      button {
        background-color: #8ACE00;
        color: #fff;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
  
        &:hover {
          background-color: darken(#8ACE00, 10%);
        }
      }
    }
  }
  </style>
  