<template>
    <div class="app-menu">

      <h3 class='app-logo'>
          <img class="app-logo__img" src="/images/Spawn_logo_512px_white.png"/>
          <span class="app-logo__text">Spawnaride</span>
      </h3>
      <nav :class="['nav', { 'nav-desktop': isDesktop }]">

          <router-link :to="getLocaleLink('/dashboard')" class="nav-item" active-class="active">
            <i class="fas fa-home icon"></i>
            <span class="nav-text">Home</span>
          </router-link>
          <router-link :to="getLocaleLink('/chat')" class="nav-item" active-class="active">
            <i class="fas fa-comments icon"></i>
            <span class="nav-text">Chat</span>
          </router-link>
          <router-link :to="getLocaleLink('/search')" class="nav-item" active-class="active">
            <i class="fas fa-search icon"></i>
            <span class="nav-text">Search</span>
          </router-link>
          <router-link :to="getLocaleLink('/post-ride')" class="nav-item" active-class="active">
            <i class="fas fa-plus-circle icon"></i>
            <span class="nav-text">Create</span>
          </router-link>
          <!-- <router-link :to="getLocaleLink('/cart')" class="nav-item" active-class="active">
            <i class="fas fa-shopping-cart icon"></i>
            <span class="nav-text">Cart</span>
          </router-link> -->
          <router-link :to="getLocaleLink('/account')" class="nav-item" active-class="active">
            <div :style="'background-image:url(' + this.user.profilePicture + ')'" class="user-profile-pic">
              <i v-if="!this.user.profilePicture" class="fas fa-user icon"></i>
            </div>
            <span class="nav-text">Compte</span>
          </router-link>
      </nav>
    </div>
</template>

<script>
  export default {
    mounted() {
    },
    props: {
      user: {},
      uri: String,
      getLocaleLink: Function
    },
    name: "FooterNav",
    data() {
      return {
        isDesktop: false,
      };
    },
    created() {
      this.updateLayout();
      window.addEventListener("resize", this.updateLayout);
    },
    beforeUnmount() {
      window.removeEventListener("resize", this.updateLayout);
    },
    methods: {

      updateLayout() {
        this.isDesktop = window.innerWidth >= 768; // Adjust the breakpoint as needed
      },
    },
  };
</script>

<style scoped>
.user-profile-pic {
  width: 35px;
  height: 35px;
  border-radius: 20px;
  background-size: cover;
  background-position: center center;
}
.app-menu {
  display: inline-block;
  width: 100%;
  background: #111;
  height: 60px;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  padding: 10px;
}
.app-logo {
  position: absolute;
    padding: 0;
    margin: 0;
}
.app-logo__img {
  width: 42px;
  float: left;
}
.app-logo__text {
  color: #fff;
  position: relative;
  top: 5px;
}
.nav {
  background-color: #111;
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px; /* Height for mobile */
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
  transition: height 0.3s ease;
  z-index: 10000;
}

.nav-desktop {
  max-width: 1200px;
  margin: 0 auto;
  z-index: 1000;
  position: fixed;
  top: 10px;
  left: 0;
  right: 0;
  height: 45px; /* Reduced height for desktop */
  padding: 5px 0; /* Reduced padding */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  display: flex; /* Ensure it uses flexbox for layout */
  justify-content: space-around; /* Space items evenly */
}

.nav-item {
  color: #8ACE00;
  text-decoration: none;
  font-size: 14px; /* Smaller font size for compact design */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-item .icon {
  color: #8ACE00;
  font-size: 32px; /* Adjust icon size as needed */
}

.nav-item.active {
  border-bottom: 2px solid #8ACE00;
}

.nav-text {
  font-size: 10px; /* Smaller text size for better appearance */
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .nav {
    bottom: 0; /* Position at the bottom on mobile */
  }

  .nav-desktop {
    display: none; /* Hide desktop version on mobile */
  }
  .nav {
    padding: 2px 0;
  height: 60px;
}

.nav-desktop {
  top: 0px;
  height: 50px;
}
.app-menu {
  display: inline-block;
  width: 100%;
  background: #111;
  height: 50px;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  padding: 5px;
}

}

/* Media Query for Desktop */
@media (min-width: 768px) {
  .nav {
    display: none; /* Hide mobile version on desktop */
  }

  .nav-desktop {
    display: flex; /* Show desktop version */
  }
}
</style>
